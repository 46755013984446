import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import { FORM_NAME } from '../../constants';
import { getFormInitialValuesSelector, getIsCustomUidLoadingSelector } from '../../selectors';
import { CustomUidFormView } from './custom-uid-form-view';

const CustomUidFormWrapper = ({ isCustomUidLoading }) => {
	return <CustomUidFormView isLoading={isCustomUidLoading} />;
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
})(CustomUidFormWrapper);

const mapStateToProps = createStructuredSelector({
	initialValues: getFormInitialValuesSelector(),
	isCustomUidLoading: getIsCustomUidLoadingSelector(),
});

const CustomUidForm = connect(mapStateToProps)(withForm);

CustomUidFormWrapper.propTypes = {
	isCustomUidLoading: PropTypes.bool.isRequired,
};

export { CustomUidForm };
