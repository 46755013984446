import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import { FORM_NAME } from '../../constants';
import { getFormInitialValuesSelector, getIsSelectAccreditationLoadingSelector } from '../../selectors';
import { SelectAccreditationFormView } from './select-accreditation-form-view';

const SelectAccreditationFormWrapper = ({ isSelectAccreditationLoading, accreditationData }) => {
	return (
		<SelectAccreditationFormView isLoading={isSelectAccreditationLoading} accreditationData={accreditationData} />
	);
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
})(SelectAccreditationFormWrapper);

const mapStateToProps = createStructuredSelector({
	initialValues: getFormInitialValuesSelector(),
	isSelectAccreditationLoading: getIsSelectAccreditationLoadingSelector(),
});

const SelectAccreditationForm = connect(mapStateToProps)(withForm);

SelectAccreditationFormWrapper.propTypes = {
	isSelectAccreditationLoading: PropTypes.bool.isRequired,
	accreditationData: PropTypes.object.isRequired,
};

export { SelectAccreditationForm };
