import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Loader } from 'semantic-ui-react';
import { Field } from 'redux-form';
import './styles.less';
import { normalizeNumber } from '../../../../utils/normalizeNumber';
import { PriceIcon } from '../../../../components/text-icon';
import { FormInputField } from '../../../../semantic-ui/components/form-input-field';

export const CostFormView = ({ isLoading }) => {
	return (
		<>
			<div className="cost-form">
				{isLoading ? (
					<div className="cost-form__loader">
						<Loader active />
					</div>
				) : (
					<div className="cost-form__wrapper">
						<Grid className="custom-uid-form">
							<Grid.Row className="cost-form__content-row">
								<Grid.Column width={6} className="cost-form__content-column">
									Стоимость груза
								</Grid.Column>
								<Grid.Column width={7} className="cost-form__content-column">
									<div className="cost-form__content-column-field">
										<Field
											name="cost"
											type="text"
											normalize={normalizeNumber}
											component={FormInputField}
											autoComplete="off"
											icon={PriceIcon}
											placeholder="Укажите заявленную стоимость груза"
										/>
									</div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</div>
				)}
			</div>
		</>
	);
};

CostFormView.propTypes = {
	isLoading: PropTypes.bool.isRequired,
};
