import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import { CostFormView } from './cost-form-view';
import { getFormInitialValuesSelector, getIsCostLoadingSelector } from '../selectors';
import { FORM_NAME } from '../constants';

const CostFormWrapper = ({ isCostLoading }) => {
	return <CostFormView isLoading={isCostLoading} />;
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
})(CostFormWrapper);

const mapStateToProps = createStructuredSelector({
	initialValues: getFormInitialValuesSelector(),
	isCostLoading: getIsCostLoadingSelector(),
});

const CostForm = connect(mapStateToProps)(withForm);

CostFormWrapper.propTypes = {
	isCostLoading: PropTypes.bool.isRequired,
};

export { CostForm };
