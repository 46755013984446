export const SET_ACCORDION_INDEX = 'transports-filter/SET_ACCORDION_INDEX';
export const setAccordionIndexAction = payload => ({
	type: SET_ACCORDION_INDEX,
	payload,
});

export const SET_FILTER_OPTIONS = 'transports-filter/SET_FILTER_OPTIONS';
export const setFilterOptionsAction = payload => ({
	type: SET_FILTER_OPTIONS,
	payload,
});

export const RESET_STATE = 'transports-filter/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
