export const GET_ACCREDITATION = 'accreditation/GET_ACCREDITATION';
export const getAccreditationAction = payload => ({
	type: GET_ACCREDITATION,
	payload,
});

export const SET_ACCREDITATION = 'accreditation/SET_ACCREDITATION';
export const setAccreditationAction = payload => ({
	type: SET_ACCREDITATION,
	payload,
});

export const SET_IS_ACCREDITATION_LOADING = 'accreditation/SET_IS_ACCREDITATION_LOADING';
export const setIsAccreditationLoadingAction = payload => ({
	type: SET_IS_ACCREDITATION_LOADING,
	payload,
});

export const SEND_ACCREDITATION = 'accreditation/SEND_ACCREDITATION';
export const sendAccreditationAction = payload => ({
	type: SEND_ACCREDITATION,
	payload,
});

export const SET_IS_FORM_SENDING = 'accreditation/SET_IS_FORM_SENDING';
export const setIsFormSendingAction = payload => ({
	type: SET_IS_FORM_SENDING,
	payload,
});
