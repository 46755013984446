export const RESET_STATE = 'transports/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const GET_TRANSPORTS_LIST = 'transports/GET_TRANSPORTS_LIST';
export const getTransportsListAction = payload => ({
	type: GET_TRANSPORTS_LIST,
	payload,
});

export const SET_TRANSPORTS_LIST = 'transports/SET_TRANSPORTS_LIST';
export const setTransportsListAction = payload => ({
	type: SET_TRANSPORTS_LIST,
	payload,
});

export const SET_IS_TRANSPORTS_LIST_LOADING = 'transports/SET_IS_TRANSPORTS_LIST_LOADING';
export const setIsTransportsListLoadingAction = payload => ({
	type: SET_IS_TRANSPORTS_LIST_LOADING,
	payload,
});

export const SET_PAGINATION = 'transports/SET_PAGINATION';
export const setPaginationAction = payload => ({
	type: SET_PAGINATION,
	payload,
});

export const GET_TRANSPORTS_WITH_FILTERS = 'transports/GET_TRANSPORT_WITH_FILTERS';
export const getTransportsWithFiltersAction = payload => ({
	type: GET_TRANSPORTS_WITH_FILTERS,
	payload,
});

export const SET_TABLE_SEARCH = 'transports/SET_TABLE_SEARCH';
export const setTableSearchAction = payload => ({
	type: SET_TABLE_SEARCH,
	payload,
});
