import { ReqsCustomModalCell } from './components/reqs-custom-modal-cell';
import { ReqsCustomModalSubValueCell } from './components/reqs-custom-modal-subvalue-cell';
import DocumentIcon from '../../components/icons/document-icon.svg';
import LinkIcon from '../../components/icons/link-icon.svg';
import ImgIcon from '../../components/icons/image-icon.svg';
import DownloadIcon from '../../components/icons/download-icon.svg';
import { ReqsClaimsModalCell } from './components/reqs-claims-modal-cell';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const TABLE_COLUMNS_NAMES = [
	{ id: 'title', width: '310px' },
	{ id: 'value', width: '305px', customComponent: ReqsCustomModalCell },
	{ id: 'sub_value', customComponent: ReqsCustomModalSubValueCell },
];

export const TABLE_ROWS_NAMES = {
	customer: 'Заказчик',
	custom_uid: 'Номер заявки',
	tender_type: 'Тип ТЭО',
	is_auction_closed: 'Закрытый аукцион',
	starting_bet: 'Стартовая ставка',
	tender_tk: 'Транспортная компания',
	tender_amount: 'Стоимость доставки',
	address_from: 'Адрес отгрузки',
	address_to: 'Адрес доставки',
	product_invoice: 'Товарная накладная №',
	type: 'Характер груза',
	rate: 'Тариф доставки',
	mode: 'Температурный режим',
	weight: 'Масса',
	volume: 'Объем',
	place: 'Мест',
	cost: 'Заявленная стоимость груза',
	comment: 'Примечание',
	is_validated: 'Требуется валидация',
	is_thermo_check: 'Требуется подтверждение температурного режима',
	is_prr: 'Требуются погрузочно разгрузочные работы',
	features: 'Особенности груза',
	description: 'Примечание к ПРР',
	date: 'Дата создания',
	author: 'Автор',
	offer_author: 'Автор ставки',
	tk: 'Транспортная компания',
	driver: 'Водитель',
	car: 'Машина',
	trailer: 'Прицеп',
	flight: 'Рейс',
	sum: 'Стоимость доставки',
	chosen_comment: 'Примечание',
	delivery_date: 'Дата доставки',
	created_at: 'Дата создания',
	cargo: 'Габаритные характеристики',
	done_at: 'Фактическая дата доставки',
	type_seat: 'Тип места',
};

export const APPROVAL_TABLE_ROWS_NAMES = {
	...TABLE_ROWS_NAMES,
	bid: 'Ваша ставка',
};

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';

export const DOCUMENTS_TAB_ICONS = {
	document: DocumentIcon,
	image: ImgIcon,
	registry: LinkIcon,
	download: DownloadIcon,
};

export const ROUTING_ICONS = {
	note: 'note',
	product_invoice: 'product_invoice',
	custom_uid: 'custom_uid',
	flight: 'specify_flight',
	chosen_comment: 'chosen_comment',
	cost: 'cost',
};

export const CLAIMS_TABLE_COLUMNS = [
	{
		id: 'uuid',
		name: '№',
		width: '60px',
		customComponent: ReqsClaimsModalCell,
	},
	{
		id: 'created_at',
		name: 'Дата',
		width: '100px',
	},
	{
		id: 'category',
		name: 'Категория',
		width: '100px',
	},
	{
		id: 'priority',
		name: 'Приоритет',
		width: '100px',
	},
	{
		id: 'status',
		name: 'Статус',
		width: '120px',
	},
];

export const CLAIM_FORM_NAME = 'claimForm';
export const REQUIRED_FIELDS_CLAIM_FORM = ['theme_id', 'category_id', 'priority_id', 'problem'];
