import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { InfoIcon } from '../../../../components/info-icon';

const CustomCell = ({ rowData, columnId, onCustomCellButtonClick }) => {
	if (columnId === 'id') {
		return (
			<div className="custom-cell__id" onClick={() => onCustomCellButtonClick(rowData.id)}>
				<InfoIcon popupContent={`№${rowData[columnId]}`} link={rowData.id} />
			</div>
		);
	}
	if (columnId === 'company_name') {
		return <div className="custom-cell__company_name">{rowData.company_name}</div>;
	}
	if (columnId === 'name') {
		return <div className="custom-cell__company_name">{rowData.name}</div>;
	}
	if (columnId === 'type_name') {
		return <div className="custom-cell__type_name">{rowData.type_name}</div>;
	}
	if (columnId === 'number') {
		return <div className="custom-cell__company_name">{rowData.number}</div>;
	}
	if (columnId === 'is_accredited') {
		return <div className="custom-cell__is_accredited">{rowData.is_accredited}</div>;
	}

	return rowData[columnId];
};

CustomCell.propTypes = {
	rowData: PropTypes.object.isRequired,
	columnId: PropTypes.string.isRequired,
	onCustomCellButtonClick: PropTypes.func.isRequired,
};

export { CustomCell };
