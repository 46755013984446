export const GET_REQUEST_INFO = 'request-form/GET_REQUEST_INFO';
export const getRequestInfoAction = payload => ({
	type: GET_REQUEST_INFO,
	payload,
});

export const SET_REQUEST_INFO = 'request-form/SET_REQUEST_INFO';
export const setRequestInfoAction = payload => ({
	type: SET_REQUEST_INFO,
	payload,
});

export const SET_IS_REQUEST_INFO_LOADING = 'request-form/SET_IS_REQUEST_INFO_LOADING';
export const setIsRequestInfoLoadingAction = payload => ({
	type: SET_IS_REQUEST_INFO_LOADING,
	payload,
});

export const SET_ACTIVE_POINT_INDEX = 'request-form/SET_ACTIVE_POINT_INDEX';
export const setActivePointIndexAction = payload => ({
	type: SET_ACTIVE_POINT_INDEX,
	payload,
});

export const GET_PARTNERS = 'request-form/GET_PARTNERS';
export const getPartnersAction = payload => ({
	type: GET_PARTNERS,
	payload,
});

export const GET_PARTNERS_WITH_DEBOUNCE = 'request-form/GET_PARTNERS_WITH_DEBOUNCE';
export const getPartnersWithDebounceAction = payload => ({
	type: GET_PARTNERS_WITH_DEBOUNCE,
	payload,
});

export const SET_PARTNERS = 'request-form/SET_PARTNERS';
export const setPartnersAction = payload => ({
	type: SET_PARTNERS,
	payload,
});

export const SET_IS_PARTNERS_LOADING = 'request-form/SET_IS_PARTNERS_LOADING';
export const setIsPartnersLoadingAction = payload => ({
	type: SET_IS_PARTNERS_LOADING,
	payload,
});

export const SET_PARTNER_SEARCH_VALUE = 'request-form/SET_PARTNER_SEARCH_VALUE';
export const setPartnerSearchValueAction = payload => ({
	type: SET_PARTNER_SEARCH_VALUE,
	payload,
});

export const GET_ADDRESSES = 'request-form/GET_ADDRESSES';
export const getAddressesAction = payload => ({
	type: GET_ADDRESSES,
	payload,
});

export const GET_ADDRESS_WITH_DEBOUNCE = 'request-form/GET_ADDRESS_WITH_DEBOUNCE';
export const getAddressWithDebounceAction = payload => ({
	type: GET_ADDRESS_WITH_DEBOUNCE,
	payload,
});

export const SET_ADDRESSES = 'request-form/SET_ADDRESSES';
export const setAddressesAction = payload => ({
	type: SET_ADDRESSES,
	payload,
});

export const SET_IS_ADDRESSES_LOADING = 'request-form/SET_IS_ADDRESSES_LOADING';
export const setIsAddressesLoadingAction = payload => ({
	type: SET_IS_ADDRESSES_LOADING,
	payload,
});

export const SET_ADDRESS_SEARCH_VALUE = 'request-form/SET_ADDRESS_SEARCH_VALUE';
export const setAddressSearchValueAction = payload => ({
	type: SET_ADDRESS_SEARCH_VALUE,
	payload,
});

export const GET_CONTACTS = 'request-form/GET_CONTACTS';
export const getContactsAction = payload => ({
	type: GET_CONTACTS,
	payload,
});

export const GET_CONTACT_WITH_DEBOUNCE = 'request-form/GET_CONTACT_WITH_DEBOUNCE';
export const getContactWithDebounceAction = payload => ({
	type: GET_CONTACT_WITH_DEBOUNCE,
	payload,
});

export const SET_CONTACTS = 'request-form/SET_CONTACTS';
export const setContactsAction = payload => ({
	type: SET_CONTACTS,
	payload,
});

export const SET_IS_CONTACTS_LOADING = 'request-form/SET_IS_CONTACTS_LOADING';
export const setIsContactsLoadingAction = payload => ({
	type: SET_IS_CONTACTS_LOADING,
	payload,
});

export const SET_CONTACT_SEARCH_VALUE = 'request-form/SET_CONTACT_SEARCH_VALUE';
export const setContactSearchValueAction = payload => ({
	type: SET_CONTACT_SEARCH_VALUE,
	payload,
});

export const RESET_STATE = 'request-form/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const GET_DICTIONARIES = 'request-form/GET_DICTIONARIES';
export const getDictionariesAction = () => ({
	type: GET_DICTIONARIES,
});

export const SET_IS_DICTIONARIES_LOADING = 'request-form/SET_IS_DICTIONARIES_LOADING';
export const setIsDictionariesLoadingAction = payload => ({
	type: SET_IS_DICTIONARIES_LOADING,
	payload,
});

export const SET_MODES = 'request-form/SET_MODES';
export const setModesAction = payload => ({
	type: SET_MODES,
	payload,
});

export const SET_TYPES = 'request-form/SET_TYPES';
export const setTypesAction = payload => ({
	type: SET_TYPES,
	payload,
});

export const SET_RATES = 'request-form/SET_RATES';
export const setRatesAction = payload => ({
	type: SET_RATES,
	payload,
});

export const GET_PARTNERS_FORM_OPTIONS = 'request-form/GET_PARTNERS_FORM_OPTIONS';
export const getPartnersFormOptionsAction = payload => ({
	type: GET_PARTNERS_FORM_OPTIONS,
	payload,
});

export const SET_PARTNERS_FORM_OPTIONS = 'request-form/SET_PARTNERS_FORM_OPTIONS';
export const setPartnersFormOptionsAction = payload => ({
	type: SET_PARTNERS_FORM_OPTIONS,
	payload,
});

export const SET_IS_PARTNERS_FORM_OPTIONS_LOADING = 'request-form/SET_IS_PARTNERS_FORM_OPTIONS_LOADING';
export const setIsPartnersFormOptionsLoadingAction = payload => ({
	type: SET_IS_PARTNERS_FORM_OPTIONS_LOADING,
	payload,
});

export const SET_PARTNERS_SEARCH_VALUE = 'request-form/SET_PARTNERS_SEARCH_VALUE';
export const setPartnersSearchValueAction = payload => ({
	type: SET_PARTNERS_SEARCH_VALUE,
	payload,
});

export const CREATE_PARTNER = 'request-form/CREATE_PARTNER';
export const createPartnerAction = payload => ({
	type: CREATE_PARTNER,
	payload,
});

export const SET_IS_PARTNER_CREATING = 'request-form/SET_IS_PARTNER_CREATING';
export const setIsPartnerCreatingAction = payload => ({
	type: SET_IS_PARTNER_CREATING,
	payload,
});

export const GET_ADDRESS_FORM_OPTIONS = 'request-form/GET_ADDRESS_FORM_OPTIONS';
export const getAddressFormOptionsAction = payload => ({
	type: GET_ADDRESS_FORM_OPTIONS,
	payload,
});

export const SET_ADDRESS_FORM_OPTIONS = 'request-form/SET_ADDRESS_FORM_OPTIONS';
export const setAddressFormOptionsAction = payload => ({
	type: SET_ADDRESS_FORM_OPTIONS,
	payload,
});

export const SET_IS_ADDRESS_FORM_OPTIONS_LOADING = 'request-form/SET_IS_ADDRESS_FORM_OPTIONS_LOADING';
export const setIsAddressFormOptionsLoadingAction = payload => ({
	type: SET_IS_ADDRESS_FORM_OPTIONS_LOADING,
	payload,
});

export const SET_ADDRESS_FORM_SEARCH_VALUE = 'request-form/SET_ADDRESS_FORM_SEARCH_VALUE';
export const setAddressFormSearchValueAction = payload => ({
	type: SET_ADDRESS_FORM_SEARCH_VALUE,
	payload,
});

export const CREATE_ADDRESS = 'request-form/CREATE_ADDRESS';
export const createAddressAction = payload => ({
	type: CREATE_ADDRESS,
	payload,
});

export const SET_IS_ADDRESS_CREATING = 'request-form/SET_IS_ADDRESS_CREATING';
export const setIsAddressCreatingAction = payload => ({
	type: SET_IS_ADDRESS_CREATING,
	payload,
});

export const CREATE_CONTACT = 'request-form/CREATE_CONTACT';
export const createContactAction = payload => ({
	type: CREATE_CONTACT,
	payload,
});

export const SET_IS_CONTACT_CREATING = 'request-form/SET_IS_CONTACT_CREATING';
export const setIsContactCreatingAction = payload => ({
	type: SET_IS_CONTACT_CREATING,
	payload,
});

export const DELETE_REQUEST = 'request-form/DELETE_REQUEST';
export const deleteRequestAction = payload => ({
	type: DELETE_REQUEST,
	payload,
});

export const CREATE_REQUEST = 'request-form/CREATE_REQUEST';
export const createRequestAction = payload => ({
	type: CREATE_REQUEST,
	payload,
});

export const UPDATE_REQUEST = 'request-form/UPDATE_REQUEST';
export const updateRequestAction = payload => ({
	type: UPDATE_REQUEST,
	payload,
});

export const DELETE_POINT_INFO = 'request-form/DELETE_POINT_INFO';
export const deletePointInfoAction = payload => ({
	type: DELETE_POINT_INFO,
	payload,
});

export const SET_FEATURES = 'request-form/SET_FEATURES';
export const setFeaturesAction = payload => ({
	type: SET_FEATURES,
	payload,
});

export const SET_TENDER_TYPES = 'request-form/SET_TENDER_TYPES';
export const setTenderTypesAction = payload => ({
	type: SET_TENDER_TYPES,
	payload,
});

export const SET_TENDER_COMPANIES = 'request-form/SET_TENDER_COMPANIES';
export const setTenderCompaniesAction = payload => ({
	type: SET_TENDER_COMPANIES,
	payload,
});
export const SET_SEAT_TYPES = 'request-form/SET_SEAT_TYPES';
export const setSeatTypesAction = payload => ({
	type: SET_SEAT_TYPES,
	payload,
});
