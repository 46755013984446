import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Loader } from 'semantic-ui-react';
import './styles.less';
import { Field } from 'redux-form';

import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { TableComponent } from '../../../../../components/table-component';
import { TABLE_COLUMNS } from '../../../constants';
import { FormCheckboxField } from '../../../../../semantic-ui/components/form-checkbox-field';
import { formatDate } from '../../../utils';

const SelectAccreditationFormView = ({ isLoading, accreditationData }) => {
	const { pathname } = useLocation();

	const type = pathname.split('/')[2];

	const formattedRows = (accreditationData?.modes || []).map((mode, index) => ({
		id: mode.id,
		mode_name: `${mode.mode.name} °C`,
		is_validated: mode.is_validated ? 'Да' : 'Нет',
		date: mode.date ? formatDate(mode.date) : 'Нет',
		is_approved: (
			<Field name={`modes[${index}].is_approved`} type="checkbox" toggle component={FormCheckboxField} />
		),
	}));

	return (
		<div className="custom-accreditation-form">
			{isLoading ? (
				<div className="custom-accreditation-form__loader">
					<Loader active />
				</div>
			) : (
				<>
					{formattedRows.length ? (
						<Grid>
							<Grid.Row>
								<Grid.Column>
									<div>
										<TableComponent
											isWithoutHeader
											customHeaderClassName="custom-accreditation-form__table-header"
											columns={TABLE_COLUMNS}
											rows={formattedRows}
											isTableDataLoading={isLoading}
											isWithoutFooter
										/>
									</div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					) : null}
					<div className="custom-accreditation-form__toggle">
						<span>{type === 'cars' ? 'Машина аккредитована' : 'Прицеп аккредитован '}</span>
						<div className="custom-accreditation-form__toggle__checkbox">
							<Field name="is_accredited" component={FormCheckboxField} toggle type="checkbox" />
						</div>
					</div>
				</>
			)}
		</div>
	);
};

SelectAccreditationFormView.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	accreditationData: PropTypes.object,
};

export { SelectAccreditationFormView };
