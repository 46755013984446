import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { FormSelectField } from '../../../../../../semantic-ui/components/form-select-field';
import { FormInputField } from '../../../../../../semantic-ui/components/form-input-field';
import './styles.less';
import StatusButton from '../../../../../../components/status-button';
import { FILTER_FORM_NAME } from '../../constants';
import { CustomDatePicker } from '../../../../../../semantic-ui/components/custom-datepicker';

export const TransportsFilterComponent = ({ onSearchButtonClick, formOptions, onKeyDown, transportType }) => {
	return (
		<div onKeyDown={onKeyDown} className="transports-filter-component">
			<Grid>
				<Grid.Row className="transports-filter-component__row">
					<Grid.Column width={16}>
						<Field
							name="search"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Поиск"
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="transports-filter-component__row">
					<Grid.Column width={16}>
						<hr />
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="transports-filter-component__row">
					<Grid.Column width={16}>
						<Field
							name="type"
							type="text"
							component={FormSelectField}
							autoComplete="off"
							search
							noResultsMessage="Не найдено"
							placeholder={`Тип ${transportType === 'cars' ? 'машины' : 'прицепа'}`}
							options={formOptions?.types || []}
							isWithoutSelectOnBlur
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="transports-filter-component__row">
					<Grid.Column width={16}>
						<Field
							name="mode"
							type="text"
							component={FormSelectField}
							autoComplete="off"
							search
							multiple
							noResultsMessage="Не найдено"
							placeholder="Температурный режим"
							options={formOptions?.modes || []}
							isWithoutSelectOnBlur
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="transports-filter-component__row">
					<Grid.Column width={16}>
						<Field
							name="tonnage"
							type="text"
							component={FormSelectField}
							autoComplete="off"
							search
							noResultsMessage="Не найдено"
							placeholder={`Грузоподъемность ${transportType === 'cars' ? 'машины' : 'прицепа'}`}
							options={formOptions?.tonnages || []}
							isWithoutSelectOnBlur
						/>
					</Grid.Column>
				</Grid.Row>

				<Grid.Row className="transports-filter-component__row">
					<Grid.Column width={16}>
						<hr />
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="transports-filter-component__row_with-label">
					<Grid.Column width={16}>
						<span className="transports-filter-component__row-label">
							{`Год выпуска ${transportType === 'cars' ? 'машины' : 'прицепа'}`}
						</span>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="transports-filter-component__row">
					<Grid.Column width={8}>
						<Field
							name="date_issue.from"
							type="text"
							component={CustomDatePicker}
							autoComplete="off"
							placeholder="от"
							loading={false}
							dateFormat={['MM.yyyy', 'MM-yyyy', 'MM/yyyy']}
							showMonthYearPicker
						/>
					</Grid.Column>
					<Grid.Column width={8}>
						<Field
							name="date_issue.to"
							type="text"
							component={CustomDatePicker}
							autoComplete="off"
							placeholder="до"
							loading={false}
							dateFormat={['MM.yyyy', 'MM-yyyy', 'MM/yyyy']}
							showMonthYearPicker
						/>
					</Grid.Column>
				</Grid.Row>

				<Grid.Row className="transports-filter-component__row">
					<Grid.Column width={16} className="transports-filter__button">
						<div className="transports-filter-component__column_with-label-button">
							<StatusButton
								button={{ name: 'Поиск', color: 'success' }}
								onClick={onSearchButtonClick}
								width="105px"
							/>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
};

const TransportsFilterForm = reduxForm({
	form: FILTER_FORM_NAME,
	enableReinitialize: true,
})(TransportsFilterComponent);

TransportsFilterComponent.propTypes = {
	onSearchButtonClick: PropTypes.func.isRequired,
	formOptions: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			}),
		),
	),
	onKeyDown: PropTypes.func.isRequired,
	transportType: PropTypes.string.isRequired,
};

export { TransportsFilterForm };
