/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import reducer from './reducer';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { getTransportsListAction, resetStateAction } from './actions';
import { getIsTransportsListLoadingSelector, getPaginationSelector, getTransportsListSelector } from './selectors';
import './styles.less';
import { PageContentWithNav } from '../../components/page-content-with-nav';
import { TableComponent } from '../../components/table-component';
import { headerTextMap, TABLE_COLUMNS, TRANSPORTS_TABS } from './constants';
import { removeModalQuery } from '../../utils/removeModalQuery';
import TransportsFilterComponent from './components/transport-filter';

const TransportContainerWrapper = ({
	onGetTransportsList,
	transportsList,
	onResetState,
	isTransportsListLoading,
	pagination,
}) => {
	useInjectSaga({ key: 'transportsContainerSaga', saga });
	useInjectReducer({ key: 'transportsContainerReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	const type = pathname.split('/')[2];

	useEffect(() => {
		onGetTransportsList({ type, searchQuery: removeModalQuery(query.toString()) });

		return () => onResetState();
	}, [pathname]);

	const handlePageChange = (_, { activePage }) => {
		query.set('page', activePage);
		history.push(`${pathname}?${query}`);
		onGetTransportsList({ type, searchQuery: query.toString() });
	};

	const handleSelectChange = (_, { value }) => {
		query.set('page', 1);
		if (value === 'all') {
			query.delete('per_page');
		} else {
			query.set('per_page', value);
		}
		history.push(`${pathname}?${query}`);
		onGetTransportsList({ type, searchQuery: query.toString() });
	};

	const handleCustomCellButtonClick = id => {
		const newQuery = `${query}&modal=${type.slice(0, -1)}&id=${id}`;
		history.push(`${pathname}?${newQuery}`);
	};

	const headerText = headerTextMap[type] || headerTextMap.default;

	return (
		<PageContentWithNav
			pageNavigationItems={TRANSPORTS_TABS}
			pageContent={
				<div className="page-content__container">
					<div className="transport-wrapper">
						<TableComponent
							headerText={headerText}
							columns={TABLE_COLUMNS(type)}
							rows={transportsList}
							pagination={
								!pagination
									? null
									: {
											...pagination,
											onPageChange: handlePageChange,
											onSelectChange: handleSelectChange,
									  }
							}
							isTableDataLoading={isTransportsListLoading}
							onCustomCellButtonClick={handleCustomCellButtonClick}
						/>
					</div>
					<div className="transport-filter-wrapper">
						<TransportsFilterComponent transportType={type} />
					</div>
				</div>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	transportsList: getTransportsListSelector(),
	isTransportsListLoading: getIsTransportsListLoadingSelector(),
	pagination: getPaginationSelector(),
});

const mapDispatchToProps = {
	onGetTransportsList: getTransportsListAction,
	onResetState: resetStateAction,
};

const TransportsContainer = connect(mapStateToProps, mapDispatchToProps)(TransportContainerWrapper);

TransportContainerWrapper.propTypes = {
	onGetTransportsList: PropTypes.func.isRequired,
	transportsList: PropTypes.arrayOf(PropTypes.object),
	isTransportsListLoading: PropTypes.bool.isRequired,
	pagination: PropTypes.shape({
		currentPage: PropTypes.number,
		totalPages: PropTypes.number.isRequired,
		total: PropTypes.number,
		from: PropTypes.number,
		to: PropTypes.number,
		isNextItem: PropTypes.bool,
		isPrevItem: PropTypes.bool,
		isFirstItem: PropTypes.bool,
		isLastItem: PropTypes.bool,
	}),
	onResetState: PropTypes.func.isRequired,
	tabs: PropTypes.arrayOf(PropTypes.object),
};

export default TransportsContainer;
