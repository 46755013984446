import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { TrailerEventTabView } from './trailer-event-tab-view';
import { getEventDataSelector, getIsEventDataLoadingSelector } from '../../selectors';

const TrailerEventTabWrapper = ({ eventData, isEventDataLoading }) => {
	return <TrailerEventTabView eventData={eventData} isEventDataLoading={isEventDataLoading} />;
};

TrailerEventTabWrapper.propTypes = {
	eventData: PropTypes.string,
	isEventDataLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
	eventData: getEventDataSelector(),
	isEventDataLoading: getIsEventDataLoadingSelector(),
});

const TrailerEventTab = connect(mapStateToProps)(TrailerEventTabWrapper);

export { TrailerEventTab };
