import React from 'react';
import PropTypes from 'prop-types';
import { TransportsFilterForm } from '../transport-filter-form';
import { CustomAccordion } from '../../../../../../semantic-ui/components/accordion';
import './styles.less';

export const TransportsFilterView = ({
	accordionIndex,
	onAccordionOpen,
	onSearchButtonClick,
	formOptions,
	isFilterClearButtonView,
	onFilterClearButtonClick,
	onKeyDown,
	transportType,
}) => {
	return (
		<CustomAccordion
			className="transports-filter"
			styled
			accordionIndex={accordionIndex}
			onAccordionOpen={onAccordionOpen}
			accordeonItems={[
				{
					title: 'Фильтр',
					HeaderComponent: <div className="my-custom-filter-title">Фильтр</div>,
					Component: (
						<TransportsFilterForm
							onSearchButtonClick={onSearchButtonClick}
							formOptions={formOptions}
							onKeyDown={onKeyDown}
							transportType={transportType}
						/>
					),
					isTitleIcon: true,
					isClearButtonView: isFilterClearButtonView,
					onClearButtonClick: onFilterClearButtonClick,
				},
			]}
		/>
	);
};

TransportsFilterView.propTypes = {
	accordionIndex: PropTypes.number.isRequired,
	onAccordionOpen: PropTypes.func.isRequired,
	onSearchButtonClick: PropTypes.func.isRequired,
	onSearchAnalyticsButtonClick: PropTypes.func.isRequired,
	formOptions: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			}),
		),
	),
	isFilterClearButtonView: PropTypes.bool.isRequired,
	onFilterClearButtonClick: PropTypes.func.isRequired,
	onKeyDown: PropTypes.func.isRequired,
	transportType: PropTypes.string.isRequired,
};
