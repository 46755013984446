import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { InfoIcon } from '../../../../components/info-icon';

const CustomCell = ({ rowData, columnId }) => {
	if (columnId === 'id') {
		return (
			<div className="custom-cell__id">
				<InfoIcon popupContent={`№ ${rowData.id}`} />
			</div>
		);
	}

	return rowData[columnId];
};

CustomCell.propTypes = {
	rowData: PropTypes.object.isRequired,
	columnId: PropTypes.string.isRequired,
};

export { CustomCell };
