export const GET_CUSTOM_UID = 'custom_uid/GET_CUSTOM_UID';
export const getCustomUidAction = payload => ({
	type: GET_CUSTOM_UID,
	payload,
});

export const SET_CUSTOM_UID = 'custom_uid/SET_CUSTOM_UID';
export const setCustomUidAction = payload => ({
	type: SET_CUSTOM_UID,
	payload,
});

export const SET_IS_CUSTOM_UID_LOADING = 'custom_uid/SET_IS_CUSTOM_UID_LOADING';
export const setIsCustomUidLoadingAction = payload => ({
	type: SET_IS_CUSTOM_UID_LOADING,
	payload,
});

export const SEND_UID = 'custom_uid/SEND_UID';
export const sendUidAction = payload => ({
	type: SEND_UID,
	payload,
});

export const SET_IS_FORM_SENDING = 'custom_uid/SET_IS_FORM_SENDING';
export const setIsFormSendingAction = payload => ({
	type: SET_IS_FORM_SENDING,
	payload,
});
