import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const selectTransportsContainerDomain = state => state.transportsContainerReducer || initialState;

export const selectRouterDomain = state => state.router || {};

export const getTransportsListSelector = () =>
	createSelector(selectTransportsContainerDomain, selectRouterDomain, ({ transportsList }) =>
		transportsList?.map(transport => ({
			id: transport.id,
			company_name: transport.company?.name,
			name: transport.name,
			type_name: transport.type?.name,
			number: transport.number,
			is_accredited: transport.is_accredited ? 'Да' : 'Нет',
		})),
	);

export const getIsTransportsListLoadingSelector = () =>
	createSelector(selectTransportsContainerDomain, ({ isTransportsListLoading }) => isTransportsListLoading);

export const getPaginationSelector = () =>
	createSelector(selectTransportsContainerDomain, ({ pagination }) => {
		if (!pagination || pagination.total === 0) {
			return null;
		}

		return {
			currentPage: pagination.current_page,
			totalPages: pagination.last_page,
			perPage: pagination.per_page,
			total: pagination.total,
			from: pagination.current_page * pagination.per_page - (pagination.per_page - 1),
			to:
				pagination.current_page === pagination.last_page
					? pagination.total
					: pagination.current_page * pagination.per_page,
			isNextItem: true,
			isPrevItem: true,
			isLastItem: false,
			isFirstItem: false,
		};
	});

export const getTableSearchSelector = () =>
	createSelector(selectTransportsContainerDomain, ({ tableSearch }) => tableSearch);
