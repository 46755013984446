import { CustomCell } from './components/transport-custom-cell';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const TABLE_COLUMNS = transportType => [
	{ name: '#', id: 'id', width: '50px', customComponent: CustomCell },
	{ name: 'Компания', id: 'company_name', width: '150px', customComponent: CustomCell },
	{ name: 'Модель', id: 'name', width: '120px', customComponent: CustomCell },
	{
		name: transportType === 'cars' ? 'Тип машины' : 'Тип прицепа',
		id: 'type_name',
		width: '120px',
		customComponent: CustomCell,
	},
	{
		name: 'Гос. номер',
		id: 'number',
		width: '100px',
		customComponent: CustomCell,
	},
	{
		name: transportType === 'cars' ? 'Машина аккредитована' : 'Прицеп аккредитован',
		id: 'is_accredited',
		width: '50px',
		customComponent: CustomCell,
	},
];
export const TRANSPORTS_TABS = [
	{ tab: 'total', text: 'Машины', href: '/quality_block/cars' },
	{ tab: 'total', text: 'Прицепы', href: '/quality_block/trailers' },
];

export const headerTextMap = {
	cars: 'Машины',
	trailers: 'Прицепы',
	default: 'Транспорт',
};
