/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { Popup } from 'semantic-ui-react';
import UsersIcon from '../../../../components/images/users-icon.svg';
import BetIcon from '../../../../components/images/bet-icon.svg';
import CalendarIcon from '../../../../components/images/calendar-icon.svg';
import RubValid from '../../../../components/images/rub-valid-icon.svg';
import RubInvalid from '../../../../components/images/rub-invalid-icon.svg';
import RubIcon from '../../../../components/images/rub.svg';
import { FormInputField } from '../../../../semantic-ui/components/form-input-field';
import { CustomDatePicker } from '../../../../semantic-ui/components/custom-datepicker';

const AuctionComponent = ({
	actions,
	auction,
	onActionButtonClick,
	reqId,
	onAuctionInfoChange,
	onKeyDown,
	isDateFieldDisabled,
	withLabels,
}) => {
	return (
		<div
			onKeyDown={e => onKeyDown(e, reqId)}
			className={`auction-component ${withLabels && 'req-status-actions-with-labels'}`}
		>
			<div className="auction-component__row auction-component__badge-wrapper">
				<div className="auction-component__column">
					<span className="auction-component__text">Кол-во уч.</span>
					<div className="auction-component__badge-wrapper">
						<img src={UsersIcon} alt="user-icon" className="auction-component__icon" />
						<div className="auction-component__badge auction-component__badge_small">{auction.players}</div>
					</div>
				</div>
				<div className="auction-component__column auction-component__column__statistic">
					<span className="auction-component__text auction-component__text">{auction.statistic?.title}</span>
					<div className="auction-component__badge">{auction.statistic?.sum}</div>
				</div>
			</div>
			<div className="auction-component__row">
				<span className="auction-component__text">Ставка</span>
				<div className="auction-component__badge-wrapper">
					<Popup
						content={
							<div
								className="auction-component__table"
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{ __html: auction?.info }}
							/>
						}
						disabled={!auction?.info}
						trigger={
							<div className="auction-component__badge-popup">
								<img src={BetIcon} alt="bet-icon" className="auction-component__icon" />
								<div className="auction-component__badge auction-component__badge_small">
									{auction.bet}
								</div>
							</div>
						}
						position="top center"
						size="mini"
						inverted
						style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
					/>

					<div className="auction-component__input">
						<FormInputField
							placeholder="Сумма"
							type="text"
							input={{
								onChange: e =>
									onAuctionInfoChange(
										e.target.value ? Number(e.target.value.replace(/[^\d]/g, '')) : '',
										'sum',
										reqId,
									),
								value: auction?.sum,
							}}
							meta={{}}
							icon={
								<div
									className={`auction-component__input-icon auction-component__input-icon_${auction?.status}`}
								>
									{auction?.status === 'success' && <img src={RubValid} alt="rub-green-icon" />}
									{auction?.status === 'danger' && <img src={RubInvalid} alt="rub-red-icon" />}
									{auction?.status === 'default' && <img src={RubIcon} alt="rub-icon" />}
								</div>
							}
							disabled={!auction.state}
							status={auction?.status}
							textPosition="center"
						/>
					</div>
				</div>
			</div>
			<div className="auction-component__row">
				<span className="auction-component__text">Дата доставки</span>
				<div className="auction-component__badge-wrapper">
					<img src={CalendarIcon} alt="calendar-icon" className="auction-component__icon" />
					<div className="auction-component__date-input">
						<CustomDatePicker
							input={{
								onChange: value => onAuctionInfoChange(value, 'date', reqId),
								value: auction?.date,
							}}
							meta={{}}
							disabled={!auction.state || isDateFieldDisabled}
							dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
							placeholder="Дата доставки"
							textPosition="center"
						/>
					</div>
				</div>
			</div>
			<div className="auction-component__buttons-row">
				{actions.unbet && (
					<div
						onClick={() => onActionButtonClick('unbet', reqId)}
						className="auction-component__button auction-component__button-unbet"
					/>
				)}
				{actions.bet && (
					<div
						onClick={() => onActionButtonClick('bet', reqId)}
						className="auction-component__button auction-component__button-bet"
					/>
				)}
			</div>
		</div>
	);
};

AuctionComponent.propTypes = {
	actions: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.bool), PropTypes.array]),
	onActionButtonClick: PropTypes.func.isRequired,
	reqId: PropTypes.number.isRequired,
	onAuctionInfoChange: PropTypes.func.isRequired,
	auction: PropTypes.shape({
		players: PropTypes.number,
		bet: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
		state: PropTypes.bool.isRequired,
		sum: PropTypes.number.isRequired,
		date: PropTypes.string.isRequired,
		statistic: PropTypes.string,
		info: PropTypes.string,
	}),
	onKeyDown: PropTypes.func.isRequired,
	isDateFieldDisabled: PropTypes.bool.isRequired,
	withLabels: PropTypes.bool,
};

export { AuctionComponent };
