/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import moment from 'moment';
import { initialState } from './reducer';
import { FORM_NAME } from './constants';

export const selectAccreditationModalDomain = state => state.accreditationModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getIsSelectAccreditationLoadingSelector = () =>
	createSelector(selectAccreditationModalDomain, ({ isAccreditationLoading }) => isAccreditationLoading);

export const getIsFormSendingSelector = () =>
	createSelector(selectAccreditationModalDomain, ({ isFormSending }) => isFormSending);

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'select_accreditation');
	});

export const getDataForRequestSelector = () => createSelector(getFormValues(FORM_NAME), formValues => formValues);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);
export const getAccreditationDataSelector = () =>
	createSelector(selectAccreditationModalDomain, ({ accreditation }) => accreditation);

export const getFormInitialValuesSelector = () =>
	createSelector(
		selectAccreditationModalDomain,
		getFormValues(FORM_NAME),
		({ accreditation, isAccreditationLoading }) => {
			if (isAccreditationLoading) {
				return {};
			}

			return {
				id: accreditation?.id || '',
				name: accreditation?.name || '',
				number: accreditation?.number || '',
				is_accredited: accreditation?.is_accredited || '',
				modes:
					accreditation?.modes && accreditation.modes.length
						? accreditation.modes.map(mode => ({
								id: mode.id,
								is_validated: mode.is_validated,
								date: mode.date ? moment(mode.date).format('YYYY-MM-DD') : '',
								is_approved: mode.is_approved,
						  }))
						: [],
			};
		},
	);
