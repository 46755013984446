import {
	RESET_STATE,
	SET_TRANSPORTS_LIST,
	SET_IS_TRANSPORTS_LIST_LOADING,
	SET_PAGINATION,
	SET_TABLE_SEARCH,
} from './actions';

export const initialState = {
	transportsList: [],
	isTransportsListLoading: false,
	pagination: null,
	tableSearch: '',
	count: {},
};

const transportsContainerReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case RESET_STATE:
			return initialState;
		case SET_TRANSPORTS_LIST:
			return { ...state, transportsList: payload };
		case SET_IS_TRANSPORTS_LIST_LOADING:
			return { ...state, isTransportsListLoading: payload };
		case SET_PAGINATION:
			return { ...state, pagination: payload };
		case SET_TABLE_SEARCH:
			return { ...state, tableSearch: payload };

		default:
			return state;
	}
};

export default transportsContainerReducer;
