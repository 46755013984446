export const GET_COST = 'cost/GET_COST';
export const getCostAction = payload => ({
	type: GET_COST,
	payload,
});

export const SET_COST = 'cost/SET_COST';
export const setCostAction = payload => ({
	type: SET_COST,
	payload,
});

export const SET_IS_COST_LOADING = 'cost/SET_IS_COST_LOADING';
export const setIsCostLoadingAction = payload => ({
	type: SET_IS_COST_LOADING,
	payload,
});

export const SEND_COST = 'cost/SEND_COST';
export const sendCostAction = payload => ({
	type: SEND_COST,
	payload,
});

export const SET_IS_FORM_SENDING = 'cost/SET_IS_FORM_SENDING';
export const setIsFormSendingAction = payload => ({
	type: SET_IS_FORM_SENDING,
	payload,
});
