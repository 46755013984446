export const GET_CAR_INFO = 'cars-modal/GET_CAR_INFO';
export const getCarInfoAction = id => ({
	type: GET_CAR_INFO,
	id,
});

export const SET_CAR_INFO = 'cars-modal/SET_CAR_INFO';
export const setCarInfoAction = payload => ({
	type: SET_CAR_INFO,
	payload,
});

export const SET_IS_CAR_INFO_LOADING = 'cars-modal/SET_IS_CAR_INFO_LOADING';
export const setIsCarInfoLoadingAction = payload => ({
	type: SET_IS_CAR_INFO_LOADING,
	payload,
});

export const DELETE_CAR = 'cars-modal/DELETE_CAR';
export const deleteCarAction = payload => ({
	type: DELETE_CAR,
	payload,
});

export const CREATE_CAR = 'cars-modal/CREATE_CAR';
export const createCarAction = payload => ({
	type: CREATE_CAR,
	payload,
});

export const UPDATE_CAR = 'cars-modal/UPDATE_CAR';
export const updateCarAction = payload => ({
	type: UPDATE_CAR,
	payload,
});

export const SET_IS_CAR_SAVING = 'cars-modal/SET_IS_CAR_SAVING';
export const setIsCarSavingAction = payload => ({
	type: SET_IS_CAR_SAVING,
	payload,
});

export const SET_IS_CAR_DELETING = 'cars-modal/SET_IS_CAR_DELETING';
export const setIsCarDeletingAction = payload => ({
	type: SET_IS_CAR_DELETING,
	payload,
});

export const RESET_STATE = 'cars-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const GET_TRAILERS_LIST = 'cars-modal/GET_TRAILERS_LIST';
export const getTrailersListAction = payload => ({
	type: GET_TRAILERS_LIST,
	payload,
});

export const SET_TRAILERS_LIST = 'cars-modal/SET_TRAILERS_LIST';
export const setTrailersListAction = payload => ({
	type: SET_TRAILERS_LIST,
	payload,
});

export const SET_IS_TRAILERS_LIST_LOADING = 'cars-modal/SET_IS_TRAILERS_LIST_LOADING';
export const setIsTrailersListLoadingAction = payload => ({
	type: SET_IS_TRAILERS_LIST_LOADING,
	payload,
});

export const GET_TONNAGES = 'cars-modal/GET_TONNAGES';
export const getTonnagesAction = id => ({
	type: GET_TONNAGES,
	id,
});

export const SET_TONNAGES = 'cars-modal/SET_TONNAGES';
export const setTonnagesAction = payload => ({
	type: SET_TONNAGES,
	payload,
});

export const SET_IS_TONNAGES_LOADING = 'cars-modal/SET_IS_TONNAGES_LOADING';
export const setIsTonnagesLoadingAction = payload => ({
	type: SET_IS_TONNAGES_LOADING,
	payload,
});

export const GET_CAR_TYPES = 'cars-modal/GET_CAR_TYPES';
export const getCarTypesAction = id => ({
	type: GET_CAR_TYPES,
	id,
});

export const SET_CAR_TYPES = 'cars-modal/SET_CAR_TYPES';
export const setCarTypesAction = payload => ({
	type: SET_CAR_TYPES,
	payload,
});

export const SET_IS_CAR_TYPES_LOADING = 'cars-modal/SET_IS_CAR_TYPES_LOADING';
export const setIsCarTypesLoadingAction = payload => ({
	type: SET_IS_CAR_TYPES_LOADING,
	payload,
});

export const GET_MODES = 'cars-modal/GET_MODES';
export const getModesAction = id => ({
	type: GET_MODES,
	id,
});

export const SET_MODES = 'cars-modal/SET_MODES';
export const setModesAction = payload => ({
	type: SET_MODES,
	payload,
});

export const SET_IS_MODES_LOADING = 'cars-modal/SET_IS_MODES_LOADING';
export const setIsModesLoadingAction = payload => ({
	type: SET_IS_MODES_LOADING,
	payload,
});

export const GET_EVENT_DATA = 'cars-details-modal/GET_EVENT_DATA';
export const getEventDataAction = payload => ({
	type: GET_EVENT_DATA,
	payload,
});

export const SET_EVENT_DATA = 'cars-details-modal/SET_EVENT_DATA';
export const setEventDataAction = payload => ({
	type: SET_EVENT_DATA,
	payload,
});

export const SET_IS_EVENT_DATA_LOADING = 'cars-details-modal/SET_IS_EVENT_DATA_LOADING';
export const setIsEventDataLoadingAction = payload => ({
	type: SET_IS_EVENT_DATA_LOADING,
	payload,
});
