import { SET_ACCREDITATION, SET_IS_ACCREDITATION_LOADING, SET_IS_FORM_SENDING } from './actions';

export const initialState = {
	accreditation: {},
	isAccreditationLoading: false,
	isFormSending: false,
};

const accreditationModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_ACCREDITATION:
			return {
				...state,
				accreditation: payload,
			};
		case SET_IS_ACCREDITATION_LOADING:
			return {
				...state,
				isAccreditationLoading: payload,
			};
		case SET_IS_FORM_SENDING:
			return {
				...state,
				isFormSending: payload,
			};
		default:
			return state;
	}
};

export default accreditationModalReducer;
