import React from 'react';
import { Accordion, Icon, Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './styles.less';
import ChevronDownIcon from '../../../components/images/chevron-down-big-icon.svg';

const CustomAccordion = ({
	accordionIndex,
	onAccordionOpen,
	styled,
	fluid,
	accordeonItems,
	isSubAccordion,
	titleStyle,
}) => {
	return (
		<Accordion
			styled={styled}
			fluid={fluid}
			as={Menu}
			vertical
			className={`${isSubAccordion ? 'accordion__sub' : ''}`}
		>
			{accordeonItems &&
				accordeonItems.map(
					(
						{
							title,
							error,
							Component,
							isTitleIcon,
							itemKey,
							isClearButtonView,
							onClearButtonClick,
							isWithSubAccordion,
							HeaderComponent,
							isWithoutChevron,
						},
						index,
					) => {
						const isActive = isSubAccordion ? accordionIndex.includes(index) : accordionIndex === index;

						return (
							<React.Fragment key={itemKey || title}>
								{title && (
									<>
										<Accordion.Title
											onClick={onAccordionOpen}
											className={error && 'error'}
											active={isActive}
											index={index}
											style={{ border: 0, borderBottom: '1px solid #d4d4d5' }}
										>
											{isTitleIcon && (
												<div className="accordion__title" style={titleStyle}>
													{HeaderComponent || (
														<div className="accordion__title-text">{title}</div>
													)}
													{!isWithoutChevron && (
														<div className="accordion__title-buttons">
															{isClearButtonView && (
																<div
																	className="accordion__title-buttons_clear"
																	onClick={onClearButtonClick}
																>
																	Очистить <Icon name="delete" color="grey" />
																</div>
															)}
															<img
																className={`accordion__chevron-icon ${
																	isActive ? 'up' : 'down'
																}`}
																src={ChevronDownIcon}
																alt="accordion-icon"
															/>
														</div>
													)}
												</div>
											)}
										</Accordion.Title>
										<Accordion.Content
											active={isActive}
											className={`accordion__content${
												isWithSubAccordion ? ' accordion__content_with-sub-accordion' : ''
											}`}
										>
											{Component}
										</Accordion.Content>
									</>
								)}
							</React.Fragment>
						);
					},
				)}
		</Accordion>
	);
};

CustomAccordion.propTypes = {
	accordionIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
	className: PropTypes.string,
	onAccordionOpen: PropTypes.func,
	accordeonItems: PropTypes.array,
	styled: PropTypes.bool,
	fluid: PropTypes.bool,
	itemKey: PropTypes.string,
	isSubAccordion: PropTypes.bool,
	isWithoutChevron: PropTypes.bool,
	titleStyle: PropTypes.object,
};
CustomAccordion.defaultProps = {
	titleStyle: {},
};

export { CustomAccordion };
