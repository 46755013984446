/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import { FORM_NAME } from './constants';

export const selectCustomUidModalDomain = state => state.customUidModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getIsCustomUidLoadingSelector = () =>
	createSelector(selectCustomUidModalDomain, ({ isCustomUidLoading }) => isCustomUidLoading);

export const getIsFormSendingSelector = () =>
	createSelector(selectCustomUidModalDomain, ({ isFormSending }) => isFormSending);

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'custom_uid');
	});

export const getDataForRequestSelector = () => createSelector(getFormValues(FORM_NAME), formValues => formValues);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getFormInitialValuesSelector = () =>
	createSelector(
		selectCustomUidModalDomain,
		getFormValues(FORM_NAME),
		({ customUid, isCustomUidLoading }, formValues) => {
			if (isCustomUidLoading) {
				return {};
			}

			return {
				custom_uid: formValues?.uid || customUid?.custom_uid || '',
			};
		},
	);
