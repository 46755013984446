export const OPTIONS_KEYS_VALUES = {
	types: 'id',
	modes: 'id',
	tonnages: 'id',
};

export const OPTIONS_KEYS_NAMES = {
	types: 'name',
	modes: 'name',
	tonnages: 'name',
};

export const FILTER_FORM_NAME = 'filter-form';

export const FILTER_FORM_FIELDS = ['search', 'type', 'mode', 'tonnage', 'date_issue.from', 'date_issue.to'];
