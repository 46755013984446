/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useMemo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { reduxForm, reset, submit } from 'redux-form';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { FORM_NAME } from './constants';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import {
	getActiveTrailerIdSelector,
	getTrailerInfoSelector,
	getTrailerNameSelector,
	getFormInitialValuesSelector,
	getIsAuditTabShownSelector,
	getIsTrailerAddFormOpenSelector,
	getIsTrailerEditFormOpenSelector,
	getIsTrailerInfoLoadingSelector,
	getIsModalShownSelector,
	getIsEventTabShownSelector,
	getTrailerTabsSelector,
} from './selectors';
import {
	createTrailerAction,
	deleteTrailerAction,
	getTrailerInfoAction,
	getTrailerTypesAction,
	getModesAction,
	getTonnagesAction,
	resetStateAction,
	updateTrailerAction,
	getEventDataAction,
} from './actions';
import { resetStateAction as resetCarsStateAction } from '../cars-modal/actions';
import { TrailerContent } from './components/trailer-content';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { getIsAvailableTrailersButtonsSelector } from '../profile/selectors';
import { getReturnToCarQuery, validate } from './utils';
import { setWindowInfoAction } from '../alert-modal/actions';
import { Audit } from '../../components/audit';
import closeModal from '../../utils/closeModal';
import { TrailerEventTab } from './components/trailer-event-tab';
import { getFromStorage } from '../../components/sidebar/utils';

const TrailersModalWrapper = ({
	onGetTrailerInfo,
	isTrailerInfoLoading,
	isTrailerEditFormOpen,
	isTrailerAddFormOpen,
	trailerInfo,
	trailerName,
	onResetState,
	onDeleteTrailer,
	isModalShown,
	activeTrailerId,
	onCreateTrailer,
	onUpdateTrailer,
	onClearForm,
	isAvailable,
	onFormSubmit,
	onSetWindowInfo,
	isAuditTabShown,
	isEventTabShown,
	onGetTonnages,
	onGetTrailerTypes,
	onGetModes,
	onResetCarState,
	trailerTabs,
	onGetEventData,
}) => {
	useInjectSaga({ key: 'trailersModalSaga', saga });
	useInjectReducer({ key: 'trailersModalReducer', reducer });

	const { search, pathname } = useLocation();

	let query = new URLSearchParams(search);

	const history = useHistory();

	const tab = query.get('tab');

	const mode = query.get('mode');

	const rules = getFromStorage('rules');

	useEffect(() => {
		if (isModalShown) {
			onGetTonnages();
			onGetTrailerTypes();
			onGetModes();
		}
	}, [isModalShown]);

	useEffect(() => {
		if (activeTrailerId) onGetTrailerInfo(activeTrailerId);
	}, [activeTrailerId]);

	useEffect(() => {
		if (isEventTabShown) onGetEventData(activeTrailerId);
	}, [isEventTabShown]);

	const handleTrailerFormOpenButtonClick = mode => {
		const returnTo = query.get('returnTo');

		const returnMode = query.get('returnMode');

		const returnId = query.get('returnId');

		// Если прицеп добавляется из карточки машины и нажимается кнопка отмены, то нужно вернуться назад на карточку машины
		if (query.get('mode') === 'add' && mode === 'cancel' && returnTo === 'car') {
			query = getReturnToCarQuery(query, returnTo, returnMode, returnId);
			onClearForm();

			history.push(`${pathname}?${query}`);
			return;
		}

		if (activeTrailerId) {
			query.set('id', activeTrailerId);
		} else {
			query.delete('id');
		}

		if (mode === 'edit') {
			query.set('mode', 'edit');
		}

		if (mode === 'cancel') {
			query.delete('mode');
			onClearForm();
		}

		if (mode === 'cancel' && !activeTrailerId) {
			onResetState();
			onClearForm();
			query.delete('modal');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		onResetCarState();
		closeModal(query);
		query.delete('returnId');
		query.delete('returnTo');
		query.delete('returnMode');
		onResetState();
		onClearForm();

		history.push(`${pathname}?${query}`);
	};

	const handleNavItemClick = modal => {
		if (modal === 'info') {
			query.delete('tab');
		}

		if (modal === 'audit') {
			query.set('tab', 'audit');
		}

		if (modal === 'event') {
			query.set('tab', 'event');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleTrailerDelete = () => {
		onSetWindowInfo({
			type: 'delete',
			title: 'Вы уверены?',
			text: 'Вы не сможете это отменить',
			button: {
				type: 'success',
				text: 'Да, удалить',
				onClick: () => onDeleteTrailer({ id: activeTrailerId, redirect: handleModalClose }),
			},
		});
	};

	const handleTrailerFormSubmit = async () => {
		await onFormSubmit(FORM_NAME);

		if (activeTrailerId) {
			onUpdateTrailer({
				id: activeTrailerId,
				redirect: () => handleTrailerFormOpenButtonClick('cancel'),
				searchQuery: removeModalQuery(query.toString()),
			});
			return;
		}

		const returnTo = query.get('returnTo');

		const returnMode = query.get('returnMode');

		const returnId = query.get('returnId');

		query = getReturnToCarQuery(query, returnTo, returnMode, returnId);

		onCreateTrailer({
			redirect: handleModalClose,
			resetState: onResetState,
			searchQuery: removeModalQuery(query.toString()),
			returnData: returnTo === 'car' ? { query, pathname, history } : undefined,
		});
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleTrailerFormSubmit();
		}
	};

	const modalTabContent = useMemo(() => {
		if (isAuditTabShown) {
			return <Audit entityName="trailers" entityId={activeTrailerId} />;
		}

		if (isEventTabShown) {
			return <TrailerEventTab />;
		}

		return null;
	}, [isAuditTabShown, isEventTabShown]);

	const tabs = useMemo(() => {
		const tabs = [
			{
				id: 'info',
				text: 'Информация',
				onClick: () => handleNavItemClick('info'),
				isActive: !tab && !mode,
			},
		];

		if (trailerTabs.audit) {
			tabs.push({
				id: 'audit',
				text: 'Изменения',
				onClick: () => handleNavItemClick('audit'),
				isActive: isAuditTabShown && !mode,
			});
		}

		if (trailerTabs.event) {
			tabs.push({
				id: 'event',
				text: 'События',
				onClick: () => handleNavItemClick('event'),
				isActive: isEventTabShown && !mode,
			});
		}

		return tabs;
	}, [trailerTabs, tab, mode, isAuditTabShown, isEventTabShown]);

	const handleTrailerApprove = () => {
		const query = new URLSearchParams(search);
		query.set('modal', 'select_accreditation');
		history.push(`${pathname}?${query}`);
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={
				isTrailerEditFormOpen || isTrailerAddFormOpen
					? `${isTrailerEditFormOpen ? 'Изменить прицеп' : 'Создать прицеп'}`
					: trailerName
			}
			navItems={isTrailerAddFormOpen || isTrailerEditFormOpen ? [] : tabs}
			modalContent={
				!tab ? (
					<TrailerContent
						trailerInfo={trailerInfo}
						isTrailerInfoLoading={isTrailerInfoLoading}
						isTrailerFormOpen={isTrailerEditFormOpen || isTrailerAddFormOpen}
					/>
				) : (
					modalTabContent
				)
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'trailer' }]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={
						!(isTrailerEditFormOpen || isTrailerAddFormOpen) && isAvailable.delete && !tab
							? [
									{
										onClick: handleTrailerDelete,
										text: 'Удалить',
										color: 'warning',
										disabled: isTrailerInfoLoading,
									},
							  ]
							: []
					}
					rightButtons={
						isTrailerEditFormOpen || isTrailerAddFormOpen
							? [
									{
										onClick: handleTrailerFormSubmit,
										text: 'Сохранить',
										color: 'primary',
										disabled: isTrailerInfoLoading,
									},
									{
										onClick: () => handleTrailerFormOpenButtonClick('cancel'),
										text: 'Отмена',
										color: 'secondary',
										disabled: isTrailerInfoLoading,
									},
							  ]
							: [
									...(isAvailable.update && !tab
										? [
												{
													onClick: () => handleTrailerFormOpenButtonClick('edit'),
													text: 'Изменить',
													color: 'primary',
													disabled: isTrailerInfoLoading,
												},
										  ]
										: []),
									...(rules?.transports?.update && !tab
										? [
												{
													onClick: handleTrailerApprove,
													text: 'Одобрить',
													color: 'success',
												},
										  ]
										: []),
									{
										onClick: handleModalClose,
										text: 'Закрыть',
										color: 'secondary',
									},
							  ]
					}
				/>
			}
		/>
	);
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(TrailersModalWrapper);

const mapStateToProps = createStructuredSelector({
	trailerInfo: getTrailerInfoSelector(),
	isTrailerInfoLoading: getIsTrailerInfoLoadingSelector(),
	isTrailerAddFormOpen: getIsTrailerAddFormOpenSelector(),
	isTrailerEditFormOpen: getIsTrailerEditFormOpenSelector(),
	trailerName: getTrailerNameSelector(),
	isModalShown: getIsModalShownSelector(),
	activeTrailerId: getActiveTrailerIdSelector(),
	initialValues: getFormInitialValuesSelector(),
	isAvailable: getIsAvailableTrailersButtonsSelector(),
	isAuditTabShown: getIsAuditTabShownSelector(),
	isEventTabShown: getIsEventTabShownSelector(),
	trailerTabs: getTrailerTabsSelector(),
});

const mapDispatchToProps = {
	onGetTrailerInfo: getTrailerInfoAction,
	onDeleteTrailer: deleteTrailerAction,
	onResetState: resetStateAction,
	onCreateTrailer: createTrailerAction,
	onUpdateTrailer: updateTrailerAction,
	onClearForm: () => reset(FORM_NAME),
	onFormSubmit: submit,
	onSetWindowInfo: setWindowInfoAction,
	onGetTonnages: getTonnagesAction,
	onGetTrailerTypes: getTrailerTypesAction,
	onGetModes: getModesAction,
	onResetCarState: resetCarsStateAction,
	onGetEventData: getEventDataAction,
};

const TrailersModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

TrailersModalWrapper.propTypes = {
	onGetTrailerInfo: PropTypes.func.isRequired,
	trailerInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]).isRequired,
		}),
	),
	isTrailerInfoLoading: PropTypes.bool.isRequired,
	isTrailerEditFormOpen: PropTypes.bool.isRequired,
	isTrailerAddFormOpen: PropTypes.bool.isRequired,
	trailerName: PropTypes.string.isRequired,
	onDeleteTrailer: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	activeTrailerId: PropTypes.string,
	onCreateTrailer: PropTypes.func.isRequired,
	onUpdateTrailer: PropTypes.func.isRequired,
	onClearForm: PropTypes.func.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	onFormSubmit: PropTypes.func.isRequired,
	onSetWindowInfo: PropTypes.func.isRequired,
	isAuditTabShown: PropTypes.bool.isRequired,
	isEventTabShown: PropTypes.bool.isRequired,
	onGetTonnages: PropTypes.func.isRequired,
	onGetTrailerTypes: PropTypes.func.isRequired,
	onGetModes: PropTypes.func.isRequired,
	onResetCarState: PropTypes.func.isRequired,
	trailerTabs: PropTypes.shape({
		audit: PropTypes.bool,
		event: PropTypes.bool,
	}).isRequired,
	onGetEventData: PropTypes.func.isRequired,
};

export default TrailersModal;
