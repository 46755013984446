import { put, takeEvery, call, fork } from 'redux-saga/effects';
import {
	setClaimsListAction,
	setIsClaimsListLoadingAction,
	setPaginationAction,
	GET_CLAIMS_LIST,
	setCountAction,
	GET_CLAIMS_COUNT,
} from './actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { errorHandler } from '../../api/utils';
import { getClaimsRequest, getClaimsCountRequest } from '../../api/claims';

export function* getClaimsListSaga({ payload: { status, searchQuery, noLoading } }) {
	try {
		if (!noLoading) {
			yield put(setIsClaimsListLoadingAction(true));
			yield put(setClaimsListAction([]));
		}
		const { data, meta, message } = yield call(getClaimsRequest, status, searchQuery);

		if (data) {
			yield put(
				setClaimsListAction(
					data.map(claim => ({
						...claim,
						link: `/claims/${status}?${searchQuery ? `${searchQuery}&` : ''}modal=claims_details&id=${
							claim.id
						}&req_id=${claim.req?.id}`,
						request_link: `/claims/${status}?${searchQuery ? `${searchQuery}&` : ''}modal=reqs_details&id=${
							claim.req?.id
						}`,
						created_at: new Date(claim.created_at).toLocaleDateString('ru-RU'),
					})),
				),
			);

			if (meta) {
				yield put(setPaginationAction(meta));
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsClaimsListLoadingAction(false));
	}
}
export function* getClaimsCountSaga() {
	try {
		const { data, message } = yield call(getClaimsCountRequest);

		if (data) {
			yield put(setCountAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export default function* claimsContainerSaga() {
	yield takeEvery(GET_CLAIMS_LIST, getClaimsListSaga);
	yield takeEvery(GET_CLAIMS_COUNT, getClaimsCountSaga);
}
