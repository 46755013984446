export const GET_TRAILER_INFO = 'trailers-modal/GET_TRAILER_INFO';
export const getTrailerInfoAction = id => ({
	type: GET_TRAILER_INFO,
	id,
});

export const SET_TRAILER_INFO = 'trailers-modal/SET_TRAILER_INFO';
export const setTrailerInfoAction = payload => ({
	type: SET_TRAILER_INFO,
	payload,
});

export const SET_IS_TRAILER_INFO_LOADING = 'trailers-modal/SET_IS_TRAILER_INFO_LOADING';
export const setIsTrailerInfoLoadingAction = payload => ({
	type: SET_IS_TRAILER_INFO_LOADING,
	payload,
});

export const DELETE_TRAILER = 'trailers-modal/DELETE_TRAILER';
export const deleteTrailerAction = payload => ({
	type: DELETE_TRAILER,
	payload,
});

export const CREATE_TRAILER = 'trailers-modal/CREATE_TRAILER';
export const createTrailerAction = payload => ({
	type: CREATE_TRAILER,
	payload,
});

export const UPDATE_TRAILER = 'trailers-modal/UPDATE_TRAILER';
export const updateTrailerAction = payload => ({
	type: UPDATE_TRAILER,
	payload,
});

export const SET_IS_TRAILER_SAVING = 'trailers-modal/SET_IS_TRAILER_SAVING';
export const setIsTrailerSavingAction = payload => ({
	type: SET_IS_TRAILER_SAVING,
	payload,
});

export const SET_IS_TRAILER_DELETING = 'trailers-modal/SET_IS_TRAILER_DELETING';
export const setIsTrailerDeletingAction = payload => ({
	type: SET_IS_TRAILER_DELETING,
	payload,
});

export const RESET_STATE = 'trailers-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const GET_TONNAGES = 'trailers-modal/GET_TONNAGES';
export const getTonnagesAction = id => ({
	type: GET_TONNAGES,
	id,
});

export const SET_TONNAGES = 'trailers-modal/SET_TONNAGES';
export const setTonnagesAction = payload => ({
	type: SET_TONNAGES,
	payload,
});

export const SET_IS_TONNAGES_LOADING = 'trailers-modal/SET_IS_TONNAGES_LOADING';
export const setIsTonnagesLoadingAction = payload => ({
	type: SET_IS_TONNAGES_LOADING,
	payload,
});

export const GET_TRAILER_TYPES = 'trailers-modal/GET_TRAILER_TYPES';
export const getTrailerTypesAction = id => ({
	type: GET_TRAILER_TYPES,
	id,
});

export const SET_TRAILER_TYPES = 'trailers-modal/SET_TRAILER_TYPES';
export const setTrailerTypesAction = payload => ({
	type: SET_TRAILER_TYPES,
	payload,
});

export const SET_IS_TRAILER_TYPES_LOADING = 'trailers-modal/SET_IS_TRAILER_TYPES_LOADING';
export const setIsTrailerTypesLoadingAction = payload => ({
	type: SET_IS_TRAILER_TYPES_LOADING,
	payload,
});

export const GET_MODES = 'trailers-modal/GET_MODES';
export const getModesAction = id => ({
	type: GET_MODES,
	id,
});

export const SET_MODES = 'trailers-modal/SET_MODES';
export const setModesAction = payload => ({
	type: SET_MODES,
	payload,
});

export const SET_IS_MODES_LOADING = 'trailers-modal/SET_IS_MODES_LOADING';
export const setIsModesLoadingAction = payload => ({
	type: SET_IS_MODES_LOADING,
	payload,
});

export const GET_EVENT_DATA = 'trailers-details-modal/GET_EVENT_DATA';
export const getEventDataAction = payload => ({
	type: GET_EVENT_DATA,
	payload,
});

export const SET_EVENT_DATA = 'trailers-details-modal/SET_EVENT_DATA';
export const setEventDataAction = payload => ({
	type: SET_EVENT_DATA,
	payload,
});

export const SET_IS_EVENT_DATA_LOADING = 'trailers-details-modal/SET_IS_EVENT_DATA_LOADING';
export const setIsEventDataLoadingAction = payload => ({
	type: SET_IS_EVENT_DATA_LOADING,
	payload,
});
