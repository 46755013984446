import { put, takeEvery, call, fork, all } from 'redux-saga/effects';
import {
	setTransportsListAction,
	setIsTransportsListLoadingAction,
	setPaginationAction,
	GET_TRANSPORTS_LIST,
} from './actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { errorHandler } from '../../api/utils';
import { getTransportsFilterRequest, getTransportsListRequest } from '../../api/transport';
import { setFilterOptionsAction } from './components/transport-filter/actions';

export function* getTransportsListSaga({ payload: { type, searchQuery } }) {
	try {
		yield put(setIsTransportsListLoadingAction(true));
		yield put(setTransportsListAction([]));

		const [{ data, meta, message }, { filter }] = yield all([
			call(getTransportsListRequest, type, searchQuery),
			call(getTransportsFilterRequest, type),
		]);

		if (data) {
			yield put(
				setTransportsListAction(
					data.map(transport => ({
						...transport,
						link: `${type}?${searchQuery ? `${searchQuery}&` : ''}modal=${type.slice(0, -1)}&id=${
							transport.id
						}`,
					})),
				),
			);

			if (meta) {
				yield put(
					setPaginationAction({
						current_page: meta.current_page,
						per_page: meta.per_page,
						from: meta.from,
						last_page: meta.last_page,
						total: meta.total,
					}),
				);
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
		if (filter) {
			const filters = {
				...(filter || {}),
				mode: [...(filter?.mode || []), { name: 'Отсутствует', id: 'none' }],
				...(filter?.delivery_status ? { deliveryStatus: filter.delivery_status } : {}),
			};

			yield put(setFilterOptionsAction(filters));
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsTransportsListLoadingAction(false));
	}
}

export default function* transportsContainerSaga() {
	yield takeEvery(GET_TRANSPORTS_LIST, getTransportsListSaga);
}
