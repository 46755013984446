import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { notificationInit } from '../../modules/notifications/actions';
import {
	GET_ACCREDITATION,
	SEND_ACCREDITATION,
	setAccreditationAction,
	setIsAccreditationLoadingAction,
	setIsFormSendingAction,
} from './actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { DISMISS_TIME } from '../../api/constants';
import { errorHandler } from '../../api/utils';
import { getSelectTransportAccreditationsRequest, setSelectAccreditationRequest } from '../../api/transport';
import { getDataForRequestSelector, getFormIsInvalidSelector } from './selectors';

export function* getAccreditationSaga({ payload: { type, id } }) {
	try {
		yield put(setIsAccreditationLoadingAction(true));
		const response = yield call(getSelectTransportAccreditationsRequest, type, id);

		const { data, message, toast } = response;

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}
			yield put(setAccreditationAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsAccreditationLoadingAction(false));
	}
}

export function* sendAccreditationSaga({ payload: { id, type, redirect } }) {
	try {
		yield put(setIsFormSendingAction(true));
		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForRequest = yield select(getDataForRequestSelector());

		const { data, message, toast } = yield call(setSelectAccreditationRequest, id, type, dataForRequest);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}
			redirect();
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsFormSendingAction(false));
	}
}

export default function* selectAccreditationModalSaga() {
	yield takeEvery(GET_ACCREDITATION, getAccreditationSaga);
	yield takeEvery(SEND_ACCREDITATION, sendAccreditationSaga);
}
