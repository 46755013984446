import {
	RESET_STATE,
	SET_CAR_INFO,
	SET_CAR_TYPES,
	SET_EVENT_DATA,
	SET_IS_CAR_DELETING,
	SET_IS_CAR_INFO_LOADING,
	SET_IS_CAR_SAVING,
	SET_IS_CAR_TYPES_LOADING,
	SET_IS_EVENT_DATA_LOADING,
	SET_IS_MODES_LOADING,
	SET_IS_TONNAGES_LOADING,
	SET_IS_TRAILERS_LIST_LOADING,
	SET_MODES,
	SET_TONNAGES,
	SET_TRAILERS_LIST,
} from './actions';

export const initialState = {
	carInfo: null,
	isCarInfoLoading: false,
	isCarDeleting: false,
	isCarSaving: false,
	trailersList: [],
	isTrailersListLoading: false,
	isTonnagesLoading: false,
	isCarTypesLoading: false,
	isModesLoading: false,
	tonnages: [],
	carTypes: [],
	modes: [],
	eventData: null,
	isEventDataLoading: false,
};

const carsModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_CAR_INFO:
			return {
				...state,
				carInfo: payload,
			};
		case SET_IS_CAR_INFO_LOADING:
			return {
				...state,
				isCarInfoLoading: payload,
			};
		case SET_IS_CAR_DELETING:
			return {
				...state,
				isCarDeleting: payload,
			};
		case SET_IS_CAR_SAVING:
			return {
				...state,
				isCarSaving: payload,
			};
		case SET_TRAILERS_LIST:
			return {
				...state,
				trailersList: payload,
			};
		case SET_IS_TRAILERS_LIST_LOADING:
			return {
				...state,
				isTrailersListLoading: payload,
			};
		case SET_TONNAGES:
			return {
				...state,
				tonnages: payload,
			};
		case SET_IS_TONNAGES_LOADING:
			return {
				...state,
				isTonnagesLoading: payload,
			};
		case SET_CAR_TYPES:
			return {
				...state,
				carTypes: payload,
			};
		case SET_IS_CAR_TYPES_LOADING:
			return {
				...state,
				isCarTypesLoading: payload,
			};
		case SET_MODES:
			return {
				...state,
				modes: payload,
			};
		case SET_IS_MODES_LOADING:
			return {
				...state,
				isModesLoading: payload,
			};
		case SET_EVENT_DATA:
			return {
				...state,
				eventData: payload,
			};
		case SET_IS_EVENT_DATA_LOADING:
			return {
				...state,
				isEventDataLoading: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default carsModalReducer;
