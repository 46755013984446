/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { reduxForm, reset } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { ModalWindow } from '../../../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../../../semantic-ui/components/modal-window/modal-footer-buttons';
import { getSelectRejectClaimAction, rejectClaimAction } from '../../actions';
import { getFormClaimsRejectValuesSelector, getIsClaimRejectModalShownSelector } from '../../selectors';
import { useInjectSaga } from '../../../../utils/injectSaga';
import { useInjectReducer } from '../../../../utils/injectReducer';
import saga from '../../saga';
import reducer from '../../reducer';
import { RefusalReasonFormView } from '../../../refusal-reason-modal/components/refusal-reason-form';
import closeModal from '../../../../utils/closeModal';
import { CLAIMS_REJECT_FORM_NAME, REQUEST_ERROR_MESSAGE } from '../../constants';

const ClaimsRejectFormWrapper = ({
	onRejectClaim,
	isModalShown,
	onResetForm,
	onGetSelectRejectClaim,
	handleSubmit,
}) => {
	if (!isModalShown) return null;
	useInjectSaga({ key: 'claimsDetailsModalSaga', saga });
	useInjectReducer({ key: 'claimsDetailsModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	const status = pathname.split('/')[2];

	useEffect(() => {
		onGetSelectRejectClaim({ req_id: query.get('req_id'), id: query.get('id') });
	}, []);

	const handleClose = () => {
		closeModal(query);
		history.push(`${pathname}?${query}`);
		onResetForm(CLAIMS_REJECT_FORM_NAME);
	};

	const handleBack = () => {
		query.set('modal', 'claims_details');
		history.push(`${pathname}?${query}`);
		onResetForm(CLAIMS_REJECT_FORM_NAME);
	};

	const handleSave = () => {
		handleSubmit(values => {
			onRejectClaim({
				id: query.get('id'),
				req_id: query.get('req_id'),
				body: values,
				redirect: handleBack,
				status,
				query,
			});
		})();
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			headerText="Отклонить решение по претензии"
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleClose }]}
			modalContent={<RefusalReasonFormView title="Причина" name="reason" placeholder="Текст причины" rows={6} />}
			footerButtons={
				<ModalFooterButtons
					rightButtons={[
						{
							text: 'Сохранить',
							color: 'primary',
							type: 'submit',
							onClick: handleSave,
						},
						{
							text: 'Закрыть',
							color: 'secondary',
							onClick: handleBack,
						},
					]}
				/>
			}
		/>
	);
};

ClaimsRejectFormWrapper.propTypes = {
	onRejectClaim: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	onResetForm: PropTypes.func.isRequired,
	formValues: PropTypes.object.isRequired,
	onGetSelectRejectClaim: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	onGetClaimsList: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsClaimRejectModalShownSelector(),
	formValues: getFormClaimsRejectValuesSelector(),
});

const mapDispatchToProps = {
	onRejectClaim: rejectClaimAction,
	onResetForm: reset,
	onGetSelectRejectClaim: getSelectRejectClaimAction,
};

const ClaimsRejectForm = connect(mapStateToProps, mapDispatchToProps)(ClaimsRejectFormWrapper);

export const validate = values => {
	const errors = {};

	if (!values.reason) {
		errors.reason = REQUEST_ERROR_MESSAGE;
	}

	return errors;
};
export default reduxForm({
	form: CLAIMS_REJECT_FORM_NAME,
	enableReinitialize: true,
	validate,
})(ClaimsRejectForm);
