/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { TABLE_COLUMNS } from './constants';
import { TableComponent } from '../../components/table-component';
import { PageContentWithNav } from '../../components/page-content-with-nav';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import {
	getClaimsListSelector,
	getIsClaimsListLoadingSelector,
	getPaginationSelector,
	getTableSearchSelector,
	getTabsSelector,
} from './selectors';
import { getClaimsCountAction, getClaimsListAction, resetStateAction, setTableSearchAction } from './actions';
import { removeModalQuery } from '../../utils/removeModalQuery';

const ClaimsContainerWrapper = ({
	onGetClaimsList,
	onGetClaimsCount,
	claimsList,
	isClaimsListLoading,
	pagination,
	tableSearch,
	onSetTableSearch,
	tabs,
}) => {
	useInjectSaga({ key: 'claimsContainerSaga', saga });
	useInjectReducer({ key: 'claimsContainerReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	const status = pathname.split('/')[2];

	useEffect(() => {
		onGetClaimsList({ status, searchQuery: removeModalQuery(query.toString()) });
		onGetClaimsCount();
	}, [status]);

	useEffect(() => {
		if (query.has('search')) {
			onSetTableSearch(query.get('search').toString());
		}
	}, []);

	const handleSearch = ({ key }) => {
		if (key === 'Enter') {
			query.set('page', 1);
			if (tableSearch) {
				query.set('search', tableSearch);
			} else {
				query.delete('search');
			}
			history.push(`${pathname}?${query}`);

			onGetClaimsList({ status, searchQuery: query.toString() });
		}
	};

	const handleSearchInputChange = ({ target: { value } }) => {
		onSetTableSearch(value);
	};

	const handleSearchInputClear = () => {
		onSetTableSearch('');
		query.delete('search');
		history.push(`${pathname}?${query}`);
		onGetClaimsList({ status, searchQuery: query.toString() });
	};

	const handlePageChange = (_, { activePage }) => {
		query.set('page', activePage);
		history.push(`${pathname}?${query}`);
		onGetClaimsList({ status, searchQuery: query.toString() });
	};

	const handleSelectChange = (_, { value }) => {
		query.set('page', 1);
		if (value === 'all') {
			query.delete('per_page');
		} else {
			query.set('per_page', value);
		}
		history.push(`${pathname}?${query}`);
		onGetClaimsList({ status, searchQuery: query.toString() });
	};

	return (
		<PageContentWithNav
			pageNavigationItems={tabs}
			pageContent={
				<TableComponent
					headerText="Претензии"
					columns={TABLE_COLUMNS}
					rows={claimsList}
					pagination={
						!pagination
							? null
							: { ...pagination, onPageChange: handlePageChange, onSelectChange: handleSelectChange }
					}
					onSearch={handleSearch}
					isTableDataLoading={isClaimsListLoading}
					searchInputValue={tableSearch}
					onSearchInputChange={handleSearchInputChange}
					onSearchInputClear={handleSearchInputClear}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	claimsList: getClaimsListSelector(),
	isClaimsListLoading: getIsClaimsListLoadingSelector(),
	pagination: getPaginationSelector(),
	tableSearch: getTableSearchSelector(),
	tabs: getTabsSelector(),
});

const mapDispatchToProps = {
	onGetClaimsList: getClaimsListAction,
	onResetState: resetStateAction,
	onSetTableSearch: setTableSearchAction,
	onGetClaimsCount: getClaimsCountAction,
};

const ClaimsContainer = connect(mapStateToProps, mapDispatchToProps)(ClaimsContainerWrapper);

ClaimsContainerWrapper.propTypes = {
	onGetClaimsList: PropTypes.func.isRequired,
	onGetClaimsCount: PropTypes.func.isRequired,
	claimsList: PropTypes.arrayOf(PropTypes.object),
	isClaimsListLoading: PropTypes.bool.isRequired,
	pagination: PropTypes.shape({
		currentPage: PropTypes.number,
		totalPages: PropTypes.number.isRequired,
		total: PropTypes.number,
		from: PropTypes.number,
		to: PropTypes.number,
		isNextItem: PropTypes.bool,
		isPrevItem: PropTypes.bool,
		isFirstItem: PropTypes.bool,
		isLastItem: PropTypes.bool,
	}),
	onResetState: PropTypes.func.isRequired,
	onSetTableSearch: PropTypes.func.isRequired,
	tableSearch: PropTypes.string.isRequired,
	tabs: PropTypes.arrayOf(PropTypes.object),
};

export default ClaimsContainer;
