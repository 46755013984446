/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { reset } from 'redux-form';
import { getAccreditationAction, sendAccreditationAction, setAccreditationAction } from './actions';
import { getIsModalShownSelector, getIsFormSendingSelector, getAccreditationDataSelector } from './selectors';
import { FORM_NAME } from './constants';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import closeModal from '../../utils/closeModal';
import { SelectAccreditationForm } from './components/select-accreditation-form';

const SelectAccreditationModalWrapper = ({
	onGetAccreditation,
	isModalShown,
	onSendAccreditation,
	onResetForm,
	isFormSending,
	onSetAccreditation,
	accreditationData,
}) => {
	if (!isModalShown) return null;

	useInjectSaga({ key: 'selectAccreditationModalSaga', saga });
	useInjectReducer({ key: 'accreditationModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	const type = pathname.split('/')[2];

	const id = query.get('id');

	useEffect(() => {
		if (isModalShown && type && id) {
			onGetAccreditation({ type, id });
		}
	}, [isModalShown, type, id, onGetAccreditation]);

	const handleModalCancel = () => {
		query.set('modal', `${type.slice(0, -1)}`);
		onResetForm(FORM_NAME);
		onSetAccreditation(null);
		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		closeModal(query);
		onResetForm(FORM_NAME);
		onSetAccreditation(null);
		history.push(`${pathname}?${query}`);
	};

	const handleFormSubmit = async () => {
		onSendAccreditation({ id, type, redirect: handleModalCancel });
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={e => e.key === 'Enter' && !e.shiftKey && handleFormSubmit()}
			headerText={`${accreditationData?.name || ''}, ${accreditationData?.number || ''}`}
			modalContent={<SelectAccreditationForm accreditationData={accreditationData} />}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'Закрыть' }]}
			footerButtons={
				<ModalFooterButtons
					rightButtons={[
						{
							onClick: handleFormSubmit,
							text: 'Сохранить',
							color: 'success',
							loading: isFormSending,
							disabled: isFormSending,
						},
						{
							onClick: handleModalCancel,
							text: 'Закрыть',
							color: 'secondary',
						},
					]}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsModalShownSelector(),
	isFormSending: getIsFormSendingSelector(),
	accreditationData: getAccreditationDataSelector(),
});

const mapDispatchToProps = {
	onGetAccreditation: getAccreditationAction,
	onSendAccreditation: sendAccreditationAction,
	onResetForm: reset,
	onSetAccreditation: setAccreditationAction,
};

const SelectAccreditationModal = connect(mapStateToProps, mapDispatchToProps)(SelectAccreditationModalWrapper);

SelectAccreditationModalWrapper.propTypes = {
	onGetAccreditation: PropTypes.func.isRequired,
	onSendAccreditation: PropTypes.func.isRequired,
	isFormSending: PropTypes.bool.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	onResetForm: PropTypes.func.isRequired,
	onSetAccreditation: PropTypes.func.isRequired,
	accreditationData: PropTypes.object,
	onFormSubmit: PropTypes.func.isRequired,
};

export default SelectAccreditationModal;
