import { SET_IS_FORM_SENDING, SET_IS_COST_LOADING, SET_COST } from './actions';

export const initialState = {
	cost: null,
	isCostLoading: false,
	isFormSending: false,
};

const costModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_COST:
			return {
				...state,
				cost: payload,
			};
		case SET_IS_COST_LOADING:
			return {
				...state,
				isCostLoading: payload,
			};

		case SET_IS_FORM_SENDING:
			return {
				...state,
				isFormSending: payload,
			};
		default:
			return state;
	}
};

export default costModalReducer;
