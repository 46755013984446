import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { InfoIcon } from '../../../../components/info-icon';

const CustomCell = ({ rowData, columnId }) => {
	if (columnId === 'id') {
		return (
			<Link className="custom-cell__id" to={rowData.link}>
				<InfoIcon popupContent={`№ ${rowData[columnId]}`} link={rowData.link} />
			</Link>
		);
	}

	if (rowData.request_link) {
		return (
			<Link className="custom-cell__link" to={rowData.request_link}>
				{rowData[columnId]}
			</Link>
		);
	}

	return rowData[columnId];
};

CustomCell.propTypes = {
	rowData: PropTypes.object.isRequired,
	columnId: PropTypes.string.isRequired,
};

export { CustomCell };
