export const RESET_STATE = 'claims/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const GET_CLAIMS_LIST = 'claims/GET_CLAIMS_LIST';
export const getClaimsListAction = payload => ({
	type: GET_CLAIMS_LIST,
	payload,
});

export const SET_CLAIMS_LIST = 'claims/SET_CLAIMS_LIST';
export const setClaimsListAction = payload => ({
	type: SET_CLAIMS_LIST,
	payload,
});

export const SET_IS_CLAIMS_LIST_LOADING = 'claims/SET_IS_CLAIMS_LIST_LOADING';
export const setIsClaimsListLoadingAction = payload => ({
	type: SET_IS_CLAIMS_LIST_LOADING,
	payload,
});

export const SET_PAGINATION = 'claims/SET_PAGINATION';
export const setPaginationAction = payload => ({
	type: SET_PAGINATION,
	payload,
});

export const SET_TABLE_SEARCH = 'claims/SET_TABLE_SEARCH';
export const setTableSearchAction = payload => ({
	type: SET_TABLE_SEARCH,
	payload,
});
export const GET_CLAIMS_COUNT = 'claims/GET_CLAIMS_COUNT';
export const getClaimsCountAction = () => ({
	type: GET_CLAIMS_COUNT,
});

export const SET_CLAIMS_COUNT = 'claims/SET_CLAIMS_COUNT';
export const setCountAction = payload => ({
	type: SET_CLAIMS_COUNT,
	payload,
});
