import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { change, getFormValues, reset } from 'redux-form';
import { useHistory, useLocation } from 'react-router';
import { TransportsFilterView } from './components/transport-filter-view';
import { getFilterOptionsSelector, getIsFilterClearButtonViewSelector, getAccordionIndexSelector } from './selectors';
import { setAccordionIndexAction } from './actions';
import reducer from './reducer';
import { FILTER_FORM_FIELDS, FILTER_FORM_NAME } from './constants';
import { flattenObject, scrollToTop } from './utils';
import { useInjectReducer } from '../../../../utils/injectReducer';
import { getTransportsListAction, getTransportsWithFiltersAction } from '../../actions';

export const TransportsFilterWrapper = ({
	accordionIndex,
	onSetAccordionIndex,
	formOptions,
	filterFormValues,
	isFilterClearButtonView,
	onFilterFormReset,
	onFormChange,
	onGetTransportsList,
	transportType,
}) => {
	useInjectReducer({ key: 'transportsFilterReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		onFilterFormReset(FILTER_FORM_NAME);
		onSetAccordionIndex(0);
	}, [window.location.pathname]);

	useEffect(() => {
		FILTER_FORM_FIELDS.forEach(field => {
			if (field === 'mode' && query.has(`filter.${field}[]`)) {
				const modes = query.getAll(`filter.${field}[]`).map(mode => {
					if (mode === 'null') {
						return 'none';
					}

					return mode;
				});

				onFormChange(FILTER_FORM_NAME, field, modes);
			}

			if (field === 'search' && query.has(`${field}`)) {
				onFormChange(FILTER_FORM_NAME, field, query.get(`${field}`));
			}

			if (query.has(`filter.${field}`)) {
				onFormChange(FILTER_FORM_NAME, field, query.get(`filter.${field}`));
			}
		});
	}, []);

	const handleAccordionClick = (_, item) => {
		const { index } = item;

		const newIndex = accordionIndex === index ? -1 : index;

		onSetAccordionIndex(newIndex);
	};

	const handleSearchButtonClick = () => {
		const filters = flattenObject(filterFormValues);

		query.delete('filter.mode[]');
		query.delete('search');
		query.set('page', 1);

		FILTER_FORM_FIELDS.forEach(field => {
			query.delete(`filter.${field}`);
		});

		Object.entries(filters).forEach(([key, value]) => {
			if (!value) return;

			if (Array.isArray(value)) {
				value.forEach(item => {
					query.append(`${key}[]`, item === 'none' ? '' : item);
				});
			} else if (key.startsWith('date_issue')) {
				query.set(`filter.${key}`, value);
			} else if (key === 'search') {
				query.set('search', value);
			} else {
				query.set(`filter.${key}`, value);
			}
		});

		history.push(`${pathname}?${query.toString()}`);

		onGetTransportsList({
			type: transportType,
			searchQuery: query.toString(),
			scrollToTop,
		});
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleSearchButtonClick();
		}
	};

	const handleFilterClearButtonClick = e => {
		e.stopPropagation();
		onFilterFormReset(FILTER_FORM_NAME);
		onSetAccordionIndex(0);

		query.delete('filter.type');
		query.delete('filter.tonnage');
		query.delete('filter.date_issue.from]');
		query.delete('filter.mode[]');
		query.delete('filter.date_issue.to');
		query.delete('search');
		query.set('page', 1);

		FILTER_FORM_FIELDS.forEach(field => {
			query.delete(`filter.${field}`);
		});

		history.push(`${pathname}?${query}`);

		onGetTransportsList({ type: transportType, searchQuery: query.toString(), scrollToTop });
	};

	return (
		<TransportsFilterView
			accordionIndex={accordionIndex}
			onAccordionOpen={handleAccordionClick}
			onSearchButtonClick={handleSearchButtonClick}
			formOptions={formOptions}
			isFilterClearButtonView={isFilterClearButtonView}
			onFilterClearButtonClick={handleFilterClearButtonClick}
			onKeyDown={handleKeyDown}
			transportType={transportType}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	accordionIndex: getAccordionIndexSelector(),
	formOptions: getFilterOptionsSelector(),
	filterFormValues: getFormValues(FILTER_FORM_NAME),
	isFilterClearButtonView: getIsFilterClearButtonViewSelector(),
});

const mapDispatchToProps = {
	onSetAccordionIndex: setAccordionIndexAction,
	onFilterFormReset: reset,
	onFormChange: change,
	onGetTransportsWithFilters: getTransportsWithFiltersAction,
	onGetTransportsList: getTransportsListAction,
};

const TransportsFilterComponent = connect(mapStateToProps, mapDispatchToProps)(TransportsFilterWrapper);

TransportsFilterWrapper.propTypes = {
	accordionIndex: PropTypes.number.isRequired,
	onSetAccordionIndex: PropTypes.func.isRequired,
	formOptions: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			}),
		),
	),
	filterFormValues: PropTypes.object,
	isFilterClearButtonView: PropTypes.bool.isRequired,
	onFilterFormReset: PropTypes.func.isRequired,
	onFormChange: PropTypes.func.isRequired,
	onGetTransportsWithFilters: PropTypes.func.isRequired,
	onGetTransportsList: PropTypes.func.isRequired,
	transportType: PropTypes.string.isRequired,
};

export default TransportsFilterComponent;
