import { CustomCell } from './components/custom-cell';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';
export const FORM_NAME = 'select_accreditation';

export const TABLE_COLUMNS = [
	{
		name: '#',
		id: 'id',
		width: '50px',
		customComponent: CustomCell,
	},
	{
		name: 'Температурный режим',
		id: 'mode_name',
		width: '150px',
	},
	{
		name: 'Валидация',
		id: 'is_validated',
		width: '120px',
	},
	{
		name: 'Действительна до',
		id: 'date',
		width: '120px',
	},
	{
		name: 'Одобрен',
		id: 'is_approved',
		width: '120px',
	},
];
