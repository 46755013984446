/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import { FORM_NAME } from './constants';

export const selectCostModalDomain = state => state.costModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getIsCostLoadingSelector = () =>
	createSelector(selectCostModalDomain, ({ isCostLoading }) => isCostLoading);

export const getIsFormSendingSelector = () =>
	createSelector(selectCostModalDomain, ({ isFormSending }) => isFormSending);

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'cost');
	});

export const getDataForRequestSelector = () => createSelector(getFormValues(FORM_NAME), formValues => formValues);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getFormInitialValuesSelector = () =>
	createSelector(selectCostModalDomain, getFormValues(FORM_NAME), ({ cost, isCostLoading }, formValues) => {
		if (isCostLoading) {
			return {};
		}

		return {
			cost: formValues?.cost || cost?.cost || '',
		};
	});
