import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ClaimsTableComponent } from './components/claims-table';
import closeModal from '../../utils/closeModal';
import {
	getIsModalShownSelector,
	getActiveClaimIdSelector,
	getClaimNameSelector,
	getIsClaimDetailsLoadingSelector,
	getClaimDetailsSelector,
	getIsClaimDocsLoadingSelector,
	getClaimDocsSelector,
	getIsClaimEventsLoadingSelector,
	getClaimEventsSelector,
	getActionsSelector,
	getActiveReqIdSelector,
	getIsDocsTabShownSelector,
	getIsEventsTabShownSelector,
} from './selectors';
import {
	getClaimDetailsAction,
	getClaimDocsAction,
	getClaimEventsAction,
	agreeClaimAction,
	resetStateAction,
} from './actions';
import { ClaimsDocumentsTab } from './components/claims-documents-tab';
import { ClaimsEventTabView } from './components/claims-event-tab';
import cancelModal from '../../utils/cancelModal';

const ClaimsDetailsModalWrapper = ({
	onGetClaimDetails,
	isClaimDetailsLoading,
	claimDetails,
	claimName,
	isModalShown,
	activeClaimId,
	isDocsTabShown,
	claimDocs,
	isClaimDocsLoading,
	onGetClaimDocs,
	actions,
	onAgreeClaim,
	activeReqId,
	onResetState,
	claimEvents,
	isClaimEventsLoading,
	onGetEventsData,
	isEventsTabShown,
}) => {
	if (!isModalShown) return null;
	useInjectSaga({ key: 'claimsDetailsModalSaga', saga });
	useInjectReducer({ key: 'claimsDetailsModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	const tab = query.get('tab');

	const status = pathname.split('/')[2];

	useEffect(() => {
		if (activeClaimId && activeReqId) {
			onGetClaimDetails({ id: activeClaimId, req_id: activeReqId });
		}
	}, [activeClaimId]);

	useEffect(() => {
		if (isDocsTabShown && activeClaimId && activeReqId) {
			onGetClaimDocs({ id: activeClaimId, req_id: activeReqId });
		}
	}, [isDocsTabShown]);

	useEffect(() => {
		if (isEventsTabShown && activeClaimId && activeReqId) {
			onGetEventsData({ id: activeClaimId, req_id: activeReqId });
		}
	}, [isEventsTabShown]);

	const handleModalClose = () => {
		closeModal(query);
		onResetState();
		history.push(`${pathname}?${query}`);
	};

	const handleModalCancel = () => {
		cancelModal(query);
		onResetState();
		history.push(`${pathname}?${query}`);
	};

	const handleNavItemClick = tabName => {
		if (tabName === 'info') {
			query.delete('tab');
		} else {
			query.set('tab', tabName);
		}
		history.push(`${pathname}?${query}`);
	};

	const tabs = [
		{
			id: 'info',
			text: 'Информация',
			onClick: () => handleNavItemClick('info'),
			isActive: !tab,
		},
		{
			id: 'docs',
			text: 'Документы',
			onClick: () => handleNavItemClick('docs'),
			isActive: tab === 'docs',
		},
		{
			id: 'event',
			text: 'События',
			onClick: () => handleNavItemClick('event'),
			isActive: tab === 'event',
		},
	];

	const getModalContent = () => {
		switch (tab) {
			case 'docs':
				return <ClaimsDocumentsTab documentsData={claimDocs} isDocumentsLoading={isClaimDocsLoading} />;
			case 'event':
				return <ClaimsEventTabView isLoading={isClaimEventsLoading} eventsData={claimEvents} />;
			default:
				return <ClaimsTableComponent rows={claimDetails} isLoading={isClaimDetailsLoading} />;
		}
	};

	const modalContent = getModalContent();

	const handleOpenSolveModal = () => {
		query.set('modal', 'claims_solve');
		history.push(`${pathname}?${query}`);
	};

	const handleOpenRejectModal = () => {
		query.set('modal', 'claims_reject');
		history.push(`${pathname}?${query}`);
	};

	const getFooterButtons = () => {
		const buttons = [];

		if (actions?.solve && !tab) {
			buttons.push({
				text: 'Предложить решение',
				color: 'primary',
				onClick: handleOpenSolveModal,
			});
		}

		if (actions?.agree && !tab) {
			buttons.push({
				text: 'Согласовать',
				color: 'success',
				onClick: () => onAgreeClaim({ id: activeClaimId, req_id: activeReqId, query, status }),
			});
		}

		if (actions?.reject && !tab) {
			buttons.push({
				text: 'Отклонить',
				color: 'warning',
				onClick: handleOpenRejectModal,
			});
		}

		return buttons;
	};

	const handleOpenAttachDocsModal = () => {
		query.set('modal', 'claims_docs_attachment');
		query.delete('tab');
		history.push(`${pathname}?${query}`);
	};

	const getDocsFooterButtons = () => {
		if (!actions?.setDocuments) {
			return [];
		}

		return [{ text: 'Прикрепить документы', onClick: handleOpenAttachDocsModal, color: 'success' }];
	};

	let buttons = getFooterButtons();
	if (isDocsTabShown) {
		buttons = getDocsFooterButtons();
	}

	buttons.push({
		text: 'Закрыть',
		color: 'secondary',
		onClick: handleModalCancel,
	});

	return (
		<ModalWindow
			isModalShown={isModalShown}
			headerText={claimName}
			navItems={tabs}
			modalContent={modalContent}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: '' }]}
			footerButtons={<ModalFooterButtons rightButtons={buttons} />}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsModalShownSelector(),
	activeClaimId: getActiveClaimIdSelector(),
	activeReqId: getActiveReqIdSelector(),
	claimName: getClaimNameSelector(),
	isClaimDetailsLoading: getIsClaimDetailsLoadingSelector(),
	claimDetails: getClaimDetailsSelector(),
	isClaimDocsLoading: getIsClaimDocsLoadingSelector(),
	claimDocs: getClaimDocsSelector(),
	isDocsTabShown: getIsDocsTabShownSelector(),
	isClaimEventsLoading: getIsClaimEventsLoadingSelector(),
	claimEvents: getClaimEventsSelector(),
	actions: getActionsSelector(),
	isEventsTabShown: getIsEventsTabShownSelector(),
});

const mapDispatchToProps = {
	onGetClaimDetails: getClaimDetailsAction,
	onGetClaimDocs: getClaimDocsAction,
	onGetEventsData: getClaimEventsAction,
	onAgreeClaim: agreeClaimAction,
	onResetState: resetStateAction,
};

ClaimsDetailsModalWrapper.propTypes = {
	onGetClaimDetails: PropTypes.func.isRequired,
	onGetClaimDocs: PropTypes.func.isRequired,
	onAgreeClaim: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	activeClaimId: PropTypes.string,
	claimName: PropTypes.string,
	isClaimDetailsLoading: PropTypes.bool.isRequired,
	claimDetails: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			title: PropTypes.string,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		}),
	),
	isClaimDocsLoading: PropTypes.bool.isRequired,
	claimDocs: PropTypes.array,
	isClaimEventsLoading: PropTypes.bool.isRequired,
	claimEvents: PropTypes.string,
	actions: PropTypes.object,
	isDocsTabShown: PropTypes.bool.isRequired,
	activeReqId: PropTypes.string.isRequired,
	onGetEventsData: PropTypes.func.isRequired,
	isEventsTabShown: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsDetailsModalWrapper);
